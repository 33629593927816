import { makeStyles } from "@mui/styles";
import { baseColors } from "../../Constants/baseStyles";
import back from "../../assets/image/Fond_page_connexion.png";

const wrapperStyles = makeStyles(() => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
    boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.25)",
  },
  menu: {
    width: "300px",
    position: "fixed",
    top: "60px",
    height: "100%",
    zIndex: 999,
  },
  content: {
    width: "100%",
    top: "80px",
    heigth: "100vh",
    marginBottom: "31px",
  },
  

  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    textAlign: "center",
    zIndex: 1000,
    boxShadow: "0px -3px 3px rgba(0, 0, 0, 0.25)",
    height: "31px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  boxDrawer: {
    height: "calc(100vh - 111px)",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    backgroundImage: `url(${back})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  contentDrawer: {
    width: "300px",
    maxHeight: '85%',
    backgroundColor: baseColors.yellow,
    padding: "20px",
    display: 'flex',
    flexDirection: 'column',
  },
  btnOne: {
    width: "calc(76vh * 600 / 1024)",
    height: "45px",
    backgroundColor: baseColors.blue.dark,
    borderRadius: 0,
    marginTop: "10px",
    border: "none",
    transition: "background-color 0.3s ease",
    cursor: "pointer",
    zIndex: 1002,
    position: "relative",
  },
  btnOneMobile: {
    width: "100%",
    height: "45px",
    backgroundColor: baseColors.blue.dark,
    borderRadius: 0,
    marginTop: "10px",
    border: "none",
    transition: "background-color 0.3s ease",
    cursor: "pointer",
    zIndex: 1002,
    position: "relative",
  },
  buttonOne: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    position: "fixed",
    bottom: "41px",
    zIndex: 1001,
    width: "100%",
  },
  buttonOneMobile: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    position: "fixed",
    bottom: "31px",
    zIndex: 1001,
    width: "100%",
  },
}));

export default wrapperStyles;
