import { makeStyles } from "@mui/styles";
import { baseColors } from "../../Constants/baseStyles";

const homeStyles = makeStyles(() => ({
  content: {
    height: "100%",
  },
  boxOne: {
    maxWidth: "1280px",
    marginTop: "60px",
    marginBottom: "70px",
    marginLeft: "20px",
    marginRight: "20px",
    paddingRight: "80px",
    paddingLeft: "80px",
    boxSizing: "border-box",
    textAlign: "justify",
  },
  boxOneMobile: {
    marginTop: "60px",
    margin: '20px',
  },
  box: {
    backgroundColor: baseColors.yellow,
    height: "auto",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  boxMobile: {
    backgroundColor: baseColors.yellow,
    height: "1060px",
  },
  vinette: {
    marginTop: "30px",
    justifyContent: "center",
    display: "flex",
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center'
  },
  buttonOne: {
    marginTop: "40px",
    justifyContent: "center",
    display: "flex",
  },
  btnOne: {
    cursor: "pointer",
    width: "340px",
    height: "38px",
    backgroundColor: "white",
    borderRadius: 0,
    border: "none",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: baseColors.blue.dark,
      color: "white",
    },
  },

  btnOneX: {
    width: "340px",
    height: "38px",
    backgroundColor: "white",
    borderRadius: 0,
    border: "none",
    transition: "background-color 0.3s ease",
    cursor: "pointer",
  },
  buttonTwo: {
    justifyContent: "center",
    display: "flex",
  },

  boxTwo: {
    border: "10px solid",
    borderColor: baseColors.yellow,
    height: "510px",
    width: "78%",
    padding: "15px",
  },
  boxTwoMobile: {
    width: "100%",
    padding: "15px",
    margin: 10,
  },
  boxTwoContent: {
    display: "flex",
  },
  boxTwoContentMobile: {
    width: "100%",
    display: "block",
  },
  boxThree: {
    marginLeft: "330px",
    marginRight: "330px",
    marginTop: "60px",
    justifyContent: "center",
    display: "flex",
  },
  boxThreeMobil: {
    marginTop: "60px",
    justifyContent: "center",
    display: "flex",
  },

  ////////////////////////////
  imageBox : {
    top:0,
  },
  imageHead: {
    top:0,
    width: "100%",
    objectFit: 'cover',
  },

  imageCMC: {
    top:0,
    width: "100%",
    objectFit: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageCMCMobile: {
    top:0,
    width: "100%",
    objectFit: 'cover',
  },
  clean: {
    width: "250px",
    height: "92px",
    objectFit: 'cover'
  },
  yellowBox: {
    backgroundColor: baseColors.yellow, 
    paddingBottom: '5px',
    paddingTop: '5px'
  },
  blueBox: {
    backgroundColor: baseColors.blue.dark, 
    paddingBottom: '5px',
    paddingTop: '5px',
  },
  highlightedText: {
    display: 'inline-block',
    borderBottom: '2px solid white', 
    width: '100%',
    paddingBottom: '6px',
    cursor: 'pointer',
   },
   boxFooter: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: "30px",
    marginBottom: "30px",
   },
   boxFooterMobile: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px',
    justifyContent: 'center',
    alignItems: 'center',
   },
   boxIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '120px',
    marginTop: '10px'
   },

   footerContent: {
    display:"flex",
    flexDirection:"column",
   },
   footerContentMobile: {
    display:"flex",
    flexDirection:"column",
    alignItems: 'center'
   },
   buttonSlider: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'relative',
    width: '300px',
    border: '1px solid #ddd',
  },
  sliderButton: {
    display: 'inline-block',
    padding: '10px',
    margin: '5px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease-in-out',
  }

}));

export default homeStyles;
