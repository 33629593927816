import {
  Box,
  Grid,
  useMediaQuery,
  IconButton,
  Slide,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import headerStyles from "./HeaderStyles";
import { CustomText } from "../CustomeText/CustomText";
import NumberList from "../Menu/NumberList";
import { baseColors } from "../../Constants/baseStyles";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MenuMobile from "../../assets/icone/MenuMobile";

const items = [
  {
    index: 0,
    title: "PROJET", //submenu
  },
  {
    index: 3,
    title: "COLLABORATION INEDITE",
  },
  {
    index: 2,
    title: "Interactivité Innovante", //submenu
  },
  {
    index: 7,
    title: "FAQ",
  },
];

export const AppBarHeader = () => {
  const classes = headerStyles();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isMenu = useMediaQuery("(max-width:1278px)");
  const size = isMobile ? "18px" : "36px";
  const appBar = isMobile ? classes.appBarMobil : classes.appBar;
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const storedOpenDrawer = sessionStorage.getItem("openDrawer");
  const initialOpenDrawer = storedOpenDrawer
  ? JSON.parse(storedOpenDrawer)
  : true;
  const shouldShowNavButtons = !initialOpenDrawer;

  const handleTitle = () => {
    navigate("/");
    //scroll to top
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleClick = (section: any) => {
    window.location.href = `/#${section}`;
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const decouvrir = () => {
    //navigate("/login");
    window.location.href = process.env.PUBLIC_URL + "/phpage/connect.php"
  };

  const itemsHamburger = [ // menu hamburger tablette
    "RICARD 3.0",
    "UN PROJET HERITAGE",
    "UNE COLLABORATION INEDITE",
    "6 OBJETS UNIQUES",
    "UNE MECANIQUE INTERACTIVE",
    "UN PROJET RICHE DE SENS",
    "FAQ",
  ];

  return (
    <Grid container className={appBar} id="appBar">
      {!isMobile ? (
        <Box sx={{ width: "1280px", display: "flex" }}>
          <Box className={classes.textTitle}>
            {isMenu && shouldShowNavButtons && (
              <IconButton onClick={() => setMenuOpen(!menuOpen)}>
                {!menuOpen ? (
                  <MenuMobile
                    width={"40px"}
                    height={"40px"}
                    color={baseColors.blue.dark}
                    //bouton header a masquer
                  />
                ) : (
                  <CloseIcon
                    style={{
                      color: baseColors.blue.dark,
                      width: "40px",
                      height: "40px",
                    }}
                  />
                )}
              </IconButton>
            )}
            <img
              src={require(`../../assets/image/Logo.png`)}
              alt={`head Home`}
              className={classes.logo}
              style={{ margin: 0 }}
              onClick={handleTitle}
            />
          </Box>
          {/* {!isMenu && <NumberList items={items} />} */}
          <Box flexGrow={1} />
          {shouldShowNavButtons && (location.pathname === "/" || location.pathname === "/list") ? (
            <Box className={classes.toLog}>
              {/* <Button
                style={{
                  backgroundColor: isHovered ? baseColors.blue.dark : "white",
                  borderRadius: "25px",
                  height: "50px",
                  padding: "0px 20px 0px 20px",
                  width: isMobile ? "90%" : "238px",
                }}
                onClick={decouvrir}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <CustomText
                  color={isHovered ? "white" : ""}
                  fontSize={"14px"}
                  fontWeight={700}
                  textTransform={"uppercase"}
                  fontFamily="Ricard-Black"
                  //bouton header a masquer
                >
                  participer au projet
                </CustomText>
              </Button> */}
            </Box>
          ) : (
            <Box className={classes.toLog}></Box>
          )}
          {menuOpen && (
            <Slide direction="right" in={menuOpen} mountOnEnter unmountOnExit>
              <Box
                sx={{
                  width: "100%",
                  bgcolor: baseColors.yellow,
                  position: "fixed",
                  top: 80,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    margin: "10px",
                  }}
                ></Box>
                <Box
                  sx={{
                    height: "80%",
                    display: "flex",
                    padding: "5px",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    {itemsHamburger.map((item, index) => (
                      <Link
                        to={"section" + (index + 1).toString()}
                        smooth={"easeInOutQuart"}
                        duration={500}
                        onClick={() =>
                          handleClick("section" + (index + 1).toString())
                        }
                      >
                        <Grid
                          container
                          key={index}
                          alignItems="center"
                          style={{
                            marginBottom: "20px",
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                          display={"flex"}
                          onClick={() => setMenuOpen(!menuOpen)}
                        >
                          <Grid item xs={2}>
                            <Box className={classes.numberList}>
                              <CustomText fontSize={"16px"} fontWeight={900}>
                                {index + 1}
                              </CustomText>
                            </Box>
                          </Grid>
                          <Grid item xs={10}>
                            <CustomText
                              fontSize={"16px"}
                              fontWeight={900}
                              textTransform="uppercase"
                              fontFamily="Ricard-Black"
                            >
                              {item}
                            </CustomText>
                          </Grid>
                        </Grid>
                      </Link>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Slide>
          )}
        </Box>
      ) : (
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          onClick={handleTitle}
        >
          <img
            src={require(`../../assets/image/Logo.png`)}
            alt={`head Home`}
            className={classes.logo}
            style={{ margin: 0 }}
          />
        </Box>
      )}
    </Grid>
  );
};
