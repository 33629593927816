import React from "react";

const iconArrowRightMob = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59"
      height="45"
      viewBox="4 2 59 45"
      fill="none"
    >
      <g filter="url(#filter0_d_63_6005)">
        <path
          d="M25.7526 34.3374L26.9183 32.8631L23.0098 29.5374L28.0498 23.1602L25.7526 20.2459L26.4041 19.6974L23.0098 15.4459L30.3812 9.24023L40.9069 22.4745L40.3583 23.1602L43.6498 27.2745L33.1241 40.6117L25.7526 34.3374ZM38.7126 22.4745L30.1412 11.6745L25.3755 15.6859L30.7926 22.4745L25.3755 29.2974L30.1412 33.3431L38.7126 22.4745Z"
          fill="#00226C"
        />
        <path
          d="M30.1412 33.3431L25.3755 29.2974L30.7926 22.4745L25.3755 15.6859L30.1412 11.6745L38.7126 22.4745L30.1412 33.3431Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_63_6005"
          x="0"
          y="0"
          width="63"
          height="53"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_63_6005"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_63_6005"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default iconArrowRightMob;