import { makeStyles } from "@mui/styles";
import { baseColors } from "../../Constants/baseStyles";

const headerStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: baseColors.yellow,
    width: "100%",
    height: "80px",
    display: "flex",
    justifyContent: "center",
  },
  appBarMobil: {
    backgroundColor: baseColors.yellow,
    width: "100%",
    height: "80px",
    display: "flex",
    alignItems: "center",
  },
  textTitle: {
    marginLeft: "20px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  numberList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    borderRadius: "50%",
    marginRight: 2,
    backgroundColor: "white",
  },
  toLog: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    width: "238px"
  },
  logo: {
    width: "250px",
    height: "69.55px" 
  }
}));

export default headerStyles;
