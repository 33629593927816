import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Provider } from "./Provider";
import { ROUTES, wrappedComponent } from "./Routes/Routes";

function App() {
  return (
    <React.StrictMode>
      <Provider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            {wrappedComponent.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                Component={route.component}
              />
            ))}
          </Routes>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
