import React from 'react'
import Wrapper from '../Components/Wrapper/Wrapper'
import { Login } from '../Screens/Login/Login'
import { Personalized } from '../Screens/Personalized/Personalized'
import { List } from '../Screens/List/List'
import { HomeNew } from '../Screens/Home/HomeNew'

export const ROUTES = {
    login: "/login",
    home: "/",
    personalPage: "/personalised",
    list: "/list"
}

export const wrappedComponent = [
    {
        key: "LOGIN",
        path: ROUTES.login,
        component : Wrapper(Login),
    },
    {
        key: "HOME",
        path: ROUTES.home,
        component: Wrapper(HomeNew)
    },
    {
        key: "PERSONNALIZAD",
        path: ROUTES.personalPage,
        component: Wrapper(Personalized)
    },
    {
        key: "LIST",
        path: ROUTES.list,
        component: Wrapper(List)
    }
]
