import { Box, useMediaQuery } from "@mui/material";
import { CustomText } from "../../Components/CustomeText/CustomText";
import IconFacebook from "../../assets/icone/iconFacebook";
import IconInsta from "../../assets/icone/iconInsta";
import IconYoutube from "../../assets/icone/iconYoutube";
import homeStyles from "./HomeStyles";

const Footer: React.FC = () => {
  const classes = homeStyles();
  const isMobile = useMediaQuery("(max-width:900px)");
  const isFold = useMediaQuery("(max-width:280px)");
  const contentOne = isMobile ? classes.boxOneMobile : classes.boxOne;
  const footerCont = isMobile
    ? classes.footerContentMobile
    : classes.footerContent;
  return (
    <>
      <Box className={contentOne} width={"1280px"} style={{marginTop: "20px", marginBottom:"30px"}}>
        <Box
          className={isMobile ? classes.boxFooterMobile : classes.boxFooter}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #00226C",
            paddingBottom: "20px",
          }}
        >
          <Box className={footerCont}>
            <Box>
              <CustomText
                fontSize="16px"
                fontWeight={400}
                textTransform="uppercase"
              >
                Pour nous suivre, c’est ici :
              </CustomText>
            </Box>
            
            <Box className={classes.boxIcon}>
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.facebook.com/ricard", "_blank")
                }
              >
                <IconFacebook />
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.instagram.com/ricard/", "_blank")
                }
              >
                <IconInsta />
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.youtube.com/@ricard", "_blank")
                }
              >
                <IconYoutube />
              </span>
            </Box>
          </Box>
          <Box
            width={isMobile ? (isFold ? "none" : "90%") : "400px"}
            display={"flex"}
            textAlign={isMobile ? "center" : "left"}
            marginTop={isMobile ? "10px" : 0}
          >
            <CustomText
              fontSize="16px"
              fontWeight={400}
              textTransform="uppercase"
            >
              POUR PLUS D’INFORMATIONS SUR LA MARQUE RICARD, RENDEZ-VOUS SUR{" "}
              <a
                href="https://www.ricard.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "currentcolor" }}
              >
                ricard.com
              </a>
            </CustomText>
          </Box>
        </Box>
        <Box className={classes.boxFooterMobile}>
          {!isMobile ? (
            <>
              <Box>
                <CustomText fontSize="10px" fontWeight={400}>
                  Pernod Ricard France SAS au capital de 54.000.000€ Les Docks,
                  10 Place de la Joliette – 13002 Marseille 303 656 375 RCS
                  MARSEILLE
                </CustomText>
              </Box>
              <Box width="200px" marginTop={"20px"}>
                <CustomText fontSize="10px" fontWeight={900}>
                  <Box style={{ cursor: "pointer" }}>
                    <a
                      style={{ textDecoration: "none" }}
                      href={process.env.PUBLIC_URL + "/phpage/CGU.pdf"}
                      target="_blank"
                    >
                      <CustomText fontSize="10px" fontWeight={400}>
                        Mentions légales et CGU
                      </CustomText>
                    </a>
                  </Box>
                </CustomText>
              </Box>
            </>
          ) : (
            <>
              <Box
                justifyContent={"center"}
                display={"flex"}
                flexDirection={"column"}
                textAlign={"center"}
              >
                <Box marginBottom={"10px"}>
                  <CustomText fontSize="10px" fontWeight={900}>
                    <Box>
                      <a
                        style={{ textDecoration: "none" }}
                        href={process.env.PUBLIC_URL + "/phpage/CGU.pdf"}
                        target="_blank"
                      >
                        <CustomText fontSize="10px" fontWeight={400}>
                          Mentions légales et CGU
                        </CustomText>
                      </a>
                    </Box>
                  </CustomText>
                </Box>
                <Box>
                  <CustomText fontSize="10px" fontWeight={400}>
                    Pernod Ricard France SAS au capital de 54.000.000€ Les
                    Docks, 10 Place de la Joliette – 13002 Marseille 303 656 375
                    RCS MARSEILLE
                  </CustomText>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Footer;
