import { makeStyles } from "@mui/styles";
import { baseColors } from "../../Constants/baseStyles";

const menuStyles = makeStyles(() => ({
  menu: {
    marginRight: "20px",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    alignItems: "center"
  },
  numberList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    borderRadius: 0,
    border: '3px solid #00226C',
    marginRight: 2,
    backgroundColor: "white",
  },
  submenu: {
    top: '100%',
    left: 0,
    width: "100%",
    height: "250px",
    position: 'absolute',
    display: 'none',
    opacity:  0,
    transition: 'opacity  0.3s ease-out',
  },
  selectedSubmenu: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    opacity:  1,
  },
  subContent: {
    margin: "20px 20px 20px",
    border: '10px solid #FFC700',
    padding: "50px"
  },
  menuLateral: {
    height: "60px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: baseColors.blue.dark,
    textAlign: "center",
    alignItems: "center",
    gap: "20px",
  }

}));

export default menuStyles;
