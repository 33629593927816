import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import homeStyles from "./HomeStyles";
import { CustomText } from "../../Components/CustomeText/CustomText";
import { baseColors } from "../../Constants/baseStyles";
import { useNavigate } from "react-router-dom";
import vinette from "../../assets/image/vignette2.png";
import Neil from "../../assets/image/Neil.png";
import FONDCOULEURCMC from "../../assets/image/FONDCOULEURCMC.png";
import Carousel from "react-material-ui-carousel";
import Footer from "./Footer";
import { Link as ScrollLink, scroller } from "react-scroll";
import { TextContext } from "../../Provider/TextColorProviders";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Navigation, Scrollbar, A11y } from "swiper/modules";

export const HomeNew = () => {
  const classes = homeStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isFold = useMediaQuery("(max-width:280px)");
  const isLitle = useMediaQuery("(max-width:1278px)");
  const isTab = useMediaQuery("(max-width:900px)");
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredCard, setIsHoveredCard] = useState(false);
  const [isHoveredTwo, setIsHoveredTwo] = useState(false);
  const contentOne = isMobile ? classes.boxOneMobile : classes.boxOne;
  const contentTwo = isMobile ? classes.boxTwoMobile : classes.boxTwo;
  const contentThree = isMobile ? classes.boxThreeMobil : classes.boxThree;
  const boxYellow = isMobile ? classes.boxMobile : classes.box;
  const size = isMobile ? "24px" : "40px";
  const [value, setValue] = useState(0);
  const refBlueBox1 = useRef<HTMLDivElement>(null);
  const refBlueBox2 = useRef<HTMLDivElement>(null);
  const { setTextColor } = useContext(TextContext);
  const largeurFixe = 306;
  const pourcentageMarginLeft = 10;
  const marginLeft = "calc(50% - 306px)";
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const [isOpen, setIsOpen] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const toggleSection = (index: any, event:any) => {
    const updatedState = isOpen.map((state, i) =>
      i === index ? !state : false
    );
    setIsOpen(updatedState);
    setTimeout(() => {
      const scrollTarget = event.target.getBoundingClientRect().top + window.pageYOffset - 150;
      window.scrollTo({ behavior: "smooth", top: scrollTarget });
    }, 100);
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const decouvrir = () => {
    //navigate("/login");
    window.location.href = process.env.PUBLIC_URL + "/phpage/connect.php"
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleItemMouseEnter = (index: any) => {
    setHoveredIndex(index);
  };

  const handleItemMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const handleMouseEnterCard = () => {
    setIsHoveredCard(true);
  };

  const handleMouseLeaveCard = () => {
    setIsHoveredCard(false);
  };

  const handleMouseEnterTwo = () => {
    setIsHoveredTwo(true);
  };

  const handleMouseLeaveTwo = () => {
    setIsHoveredTwo(false);
  };

  const handleButtonClick = (buttonTitle: string) => {
    handleButtonClickVente()
  };

  const handleButtonClickVente = () => {
    scroller.scrollTo(`${7}`, {
      smooth: true,
      duration: 500,
      offset: -50,
    });
    setIsOpen([false, false, false, false, true, false, false]);
  };

  const items = [ //menu mobile horizontal slide
    "1. RICARD 3.0",
    "2. UN PROJET HERITAGE",
    "3. UNE COLLABORATION INEDITE",
    "4. 6 OBJETS UNIQUES",
    "5. UNE MECANIQUE INTERACTIVE",
    "6. UN PROJET RICHE DE SENS",
    "7. FAQ",
  ];
  const scrollToSection = (sectionIndex: any) => {
    scroller.scrollTo(`${sectionIndex + 1}`, {
      smooth: true,
      duration: 500,
      offset: -100,
    });
  };

  const determineTextColor = (position: any) => {
    const refs = [refBlueBox1, refBlueBox2];

    const boxInfo = refs.map((ref) => {
      const boundingBox = ref.current?.getBoundingClientRect();
      return boundingBox
        ? { top: boundingBox.top, height: boundingBox.height }
        : { top: 0, height: 0 };
    });

    const isVisible = boxInfo.some((info, index) => {
      const topPosition = info.top;
      const bottomPosition = info.top + info.height;
      return (
        topPosition >= 0 &&
        bottomPosition >= 0 &&
        topPosition < window.innerHeight
      );
    });

    return isVisible ? "white" : "#00226C";
  };

  const handleScroll = () => {
    const position = window.pageYOffset + window.innerHeight / 2;
    setTextColor(determineTextColor(position));
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [refBlueBox1, refBlueBox2]);

  const generateCard = (
    i: any,
    image: any,
    title: string,
    content: any,
    contentTwo: any,
    contentThree: any,
    smallContent: any,
    bgColor: string,
    buttonContent: string,
    buttonTitle: string,
    textColor: string
  ) => {
    return (
      <Card
        key={i}
        sx={{
          border: "none",
          position: "relative",
          minHeight: "650px",
          width: "100%",
        }}
      >
        <CardMedia sx={{ height: "296px" }}>{image}</CardMedia>

        <CardContent
          style={{
            backgroundColor: bgColor,
            height: 410,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              left: 0,
              right: 0,
            }}
          >
            <div
              style={{
                display: "inline-block",
                width: "100%",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              <CustomText
                color= {(i === 0 || i === 2) ? baseColors.blue.dark : "white" }
                fontSize={isMobile ? (isFold ? "15px" : "20px") : "20px"}
                fontWeight={900}
                fontFamily="Ricard-Black"
              >
                {title}
              </CustomText>
            </div>
          </Box>
          <Box>
            <Box
              style={{
                height: "220px",
              }}
            >
              <CustomText
                color={textColor}
                fontSize={"12px"}
                fontWeight={400}
                lineHeight={"18px"}
                fontFamily="Ricard-Light"
              >
                {content}
              </CustomText>
              <Box style={{ marginTop: "10px" }}>
                <CustomText
                  color={textColor}
                  fontSize={"12px"}
                  fontWeight={400}
                  lineHeight={"18px"}
                  fontFamily="Ricard-Light"
                >
                  {contentTwo}
                </CustomText>
              </Box>
              <Box style={{ marginTop: "10px" }}>
                <CustomText
                  color={textColor}
                  fontSize={"12px"}
                  fontWeight={400}
                  lineHeight={"18px"}
                  fontFamily="Ricard-Light"
                >
                  {contentThree}
                </CustomText>
              </Box>
              <Box style={{ marginTop: "10px" }}>
                <CustomText
                  color={textColor}
                  fontSize={"10px"}
                  fontWeight={400}
                  lineHeight={"18px"}
                  fontStyle="italic"
                  fontFamily="Ricard-Light"
                >
                  {smallContent}
                </CustomText>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                bottom: "10px",
                left: 0,
                right: 0,
              }}
            >
              {i === 0 || i === 3 ? ( ''
                // <button
                //   className={classes.btnOne}
                //   onClick={decouvrir}
                //   onMouseEnter={() => handleItemMouseEnter(i)}
                //   onMouseLeave={handleItemMouseLeave}
                //   style={{ height: "30px", width: "60%" }}
                // >
                //   <CustomText
                //     color={hoveredIndex === i ? "white" : ""}
                //     fontSize={"12px"}
                //     fontWeight={700}
                //     textTransform={"uppercase"}
                //     fontFamily="Ricard-Black"
                //   >
                //     {buttonContent}
                //   </CustomText>
                // </button>
              ) : (
                <button
                  className={classes.btnOneX}
                  onClick={() => handleButtonClick(i)}
                  style={{ height: "30px", width: "60%" }}
                >
                  <CustomText
                    fontSize={"12px"}
                    fontWeight={700}
                    textTransform={"uppercase"}
                    fontFamily="Ricard-Black"
                  >
                    {buttonContent}
                  </CustomText>
                </button>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const cards = [
    generateCard(
      0,
      <>
        <img
          src={require(`../../assets/image/GrandPalais.png`)}
          alt={`head Home`}
          className={classes.imageHead}
          style={{
            margin: 0,
            position: "absolute",
            width: "100%",
            height: "296px",
            objectFit: "cover",
            zIndex: 1,
          }}
        />
        <img
          src={require(`../../assets/image/NFTParis_1.png`)}
          alt={`head Home`}
          className={classes.imageHead}
          style={{
            margin: 0,
            position: "absolute",
            width: "100%",
            height: "296px",
            objectFit: "cover",
            zIndex: 1,
          }}
        />
      </>,
      "Un lancement lors du Salon NFT PARIS, événement Web3 d’envergure",
      "Pour le lancement du projet RICARD 3.0, les 6 objets de service seront présentés au Salon NFT PARIS, l’un des plus gros événements Web3 du monde, les 23 et 24 février 2024.",
      "L’occasion d’interagir avec les objets en participant à la mécanique de personnalisation imaginée, et d’obtenir un NFT du projet RICARD 3.0* : souvenir numérique de cette collaboration inédite.",
      "",
      "",
      baseColors.yellow,
      "participer au projet",
      "btn1",
      ""
    ),
    generateCard(
      1,
      <>
        <img
          src={require(`../../assets/image/P1057449.jpg`)}
          alt={`head Home`}
          className={classes.imageHead}
          style={{
            margin: 0,
            position: "absolute",
            width: "100%",
            height: "296px",
            objectFit: "cover",
            zIndex: 1,
          }}
        />
      </>,
      "Une vente porteuse de sens",
      "A l’issue du Salon NFT PARIS, 6 autres NFTs, uniques et inspirés des paramètres de personnalisation les plus massivement choisis lors de l’événement, seront rattachés à chaque objet de service et sa bouteille en édition unique, puis vendus.",
      (<>Chaque NFT servira de certificat d’authenticité, attestant ainsi la propriété de chaque objet et de sa bouteille associée pour leur acquéreur. Chacun fera aussi office de clé d’accès à la plateforme de personnalisation des objets, en rendant ainsi le paramétrage exclusivement accessible aux détenteurs des NFTs. Enfin, ils serviront de ticket pour l’accès à une expérience Ricard unique et ultra exclusive…<br/>
      Seul l’objet symbolisant le volume de Ricard, sa bouteille en édition unique et son NFT seront proposés en vente publique, par l’intermédiaire de la maison de vente Aguttes. La vente aux enchères se déroulera le 22 avril, à l’occasion d’une vente Aguttes à Paris Expo, Porte de Versailles. Pour plus d’informations, visiter le site de la vente en cliquant <a style={{color:"white"}} href="https://www.aguttes.com/vente/147039-tour-auto-2024-la-vente-officielle" target="_blank">ici</a>.<br/><br/>
      La vente des 5 autres objets sera traitée de manière plus directe : toute demande d’information peut donc se faire par le biais de l’adresse mail <a style={{color:"white"}} href="mailto:contact@ricard.com" target="_blank">contact@ricard.com</a>.</>),
      "Tous les bénéfices des ventes seront ensuite intégralement reversés à Clean My Calanques, une association marseillaise qui oeuvre pour préserver la cité phocéenne.",
      "",
      baseColors.blue.dark,
      "PARTICIPER À LA VENTE",
      "btn2",
      "white"
    )
  ];

  const generateCardMobile = (
    i: any,
    image: any,
    title: string,
    content: any,
    contentTwo: any,
    contentThree: any,
    smallContent: any,
    bgColor: string,
    buttonContent: string,
    buttonTitle: string,
    textColor: string
  ) => {
    return (
      <Card
        key={i}
        sx={{
          border: "none",
          position: "relative",
          height: 450,
          width: "100%",
        }}
      >
        <CardMedia sx={{ height: 150 }}>{image}</CardMedia>

        <CardContent
          style={{
            backgroundColor: bgColor,
            height: 265,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              marginBottom: "10px",
              left: 0,
              right: 0,
            }}
          >
            <div
              style={{
                display: "inline-block",
                width: "100%",
                textAlign: "left",
              }}
            >
              <CustomText
                color="white"
                fontSize={isMobile ? (isFold ? "15px" : "20px") : "20px"}
                fontWeight={900}
                fontFamily="Ricard-Black"
              >
                {title}
              </CustomText>
            </div>
          </Box>
          <Box>
            <Box
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                height: isFold ? "135px" : "180px",
                overflow: "auto",
              }}
              // sx={{ scrollbarWidth: "none" }}
            >
              <CustomText
                color={textColor}
                fontSize={"12px"}
                fontWeight={400}
                lineHeight={"18px"}
                fontFamily="Ricard-Light"
              >
                {content}
              </CustomText>
              <Box style={{ marginTop: "10px" }}>
                <CustomText
                  color={textColor}
                  fontSize={"12px"}
                  fontWeight={400}
                  lineHeight={"18px"}
                  fontFamily="Ricard-Light"
                >
                  {contentTwo}
                </CustomText>
              </Box>
              <Box style={{ marginTop: "10px" }}>
                <CustomText
                  color={textColor}
                  fontSize={"12px"}
                  fontWeight={400}
                  lineHeight={"18px"}
                  fontFamily="Ricard-Light"
                >
                  {contentThree}
                </CustomText>
              </Box>
              <Box style={{ marginTop: "10px" }}>
                <CustomText
                  color={textColor}
                  fontSize={"10px"}
                  fontWeight={400}
                  lineHeight={"18px"}
                  fontStyle="italic"
                  fontFamily="Ricard-Light"
                >
                  {smallContent}
                </CustomText>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                bottom: "10px",
                left: 0,
                right: 0,
              }}
            >
              {i !== 0 && 
              <button
                className={classes.btnOneX}
                onClick={i === 0 ? decouvrir : () => handleButtonClick(i)}
                style={{ height: "30px", width: "90%" }}
              >
                <CustomText
                  fontSize={"12px"}
                  fontWeight={700}
                  textTransform={"uppercase"}
                  fontFamily="Ricard-Black"
                >
                  {buttonContent}
                </CustomText>
              </button>}
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const cardsMobile = [
    generateCardMobile(
      0,
      <>
        <img
          src={require(`../../assets/image/GrandPalais.png`)}
          alt={`head Home`}
          className={classes.imageHead}
          style={{
            margin: 0,
            position: "absolute",
            width: "100%",
            height: 150,
            objectFit: "cover",
            zIndex: 1,
          }}
        />
        <img
          src={require(`../../assets/image/NFTParis_1.png`)}
          alt={`head Home`}
          className={classes.imageHead}
          style={{
            margin: 0,
            position: "absolute",
            width: "100%",
            height: 150,
            objectFit: "cover",
            zIndex: 1,
          }}
        />
      </>,
      "Un lancement lors du Salon NFT PARIS, événement Web3 d’envergure",
      "Pour le lancement du projet RICARD 3.0, les 6 objets de service seront présentés au Salon NFT PARIS, l’un des plus gros événements Web3 du monde, les 23 et 24 février 2024.",
      "L’occasion d’interagir avec les objets en participant à la mécanique de personnalisation imaginée, et d’obtenir un NFT du projet RICARD 3.0* : souvenir numérique de cette collaboration inédite.",
      "",
      "",
      baseColors.yellow,
      "participer au projet",
      "btn1",
      ""
    ),
    generateCardMobile(
      1,
      <>
        <img
          src={require(`../../assets/image/P1057449.jpg`)}
          alt={`head Home`}
          className={classes.imageHead}
          style={{
            margin: 0,
            position: "absolute",
            width: "100%",
            height: 150,
            objectFit: "cover",
            zIndex: 1,
          }}
        />
      </>,
      "Une vente porteuse de sens",
      "A l’issue du Salon NFT PARIS, 6 autres NFTs, uniques et inspirés des paramètres de personnalisation les plus massivement choisis lors de l’événement, seront rattachés à chaque objet de service et sa bouteille en édition unique, puis vendus.",
      (<>Chaque NFT servira de certificat d’authenticité, attestant ainsi la propriété de chaque objet et de sa bouteille associée pour leur acquéreur. Chacun fera aussi office de clé d’accès à la plateforme de personnalisation des objets, en rendant ainsi le paramétrage exclusivement accessible aux détenteurs des NFTs. Enfin, ils serviront de ticket pour l’accès à une expérience Ricard unique et ultra exclusive…<br/>
      Seul l’objet symbolisant le volume de Ricard, sa bouteille en édition unique et son NFT seront proposés en vente publique, par l’intermédiaire de la maison de vente Aguttes. La vente aux enchères se déroulera le 22 avril, à l’occasion d’une vente Aguttes à Paris Expo, Porte de Versailles. Pour plus d’informations, visiter le site de la vente en cliquant <a style={{color:"white"}} href="https://www.aguttes.com/vente/147039-tour-auto-2024-la-vente-officielle" target="_blank">ici</a>.<br/><br/>
      La vente des 5 autres objets sera traitée de manière plus directe : toute demande d’information peut donc se faire par le biais de l’adresse mail <a style={{color:"white"}} href="mailto:contact@ricard.com" target="_blank">contact@ricard.com</a>.</>),
      "Tous les bénéfices des ventes seront ensuite intégralement reversés à Clean My Calanques, une association marseillaise qui oeuvre pour préserver la cité phocéenne.",
      "",
      baseColors.blue.dark,
      "PARTICIPER À LA VENTE",
      "btn2",
      "white"
    ),
  ];

  return (
    <Box>
      <Box id={"-1"} className={classes.imageBox} data-section>
        <img
          src={require(`../../assets/image/Back2.jpg`)}
          alt={`head Home`}
          className={classes.imageHead}
          style={{ margin: 0, width: "100%" }}
        />
        {isMobile && (
          <div
            style={{
              overflow: "hidden",
              border: "none",
              width: "100%",
              margin: 0,
              position: "absolute",
              marginTop: "-5px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              style={{
                display: "flex",
                transition: "transform 0.3s ease-in-out",
              }}
              TabIndicatorProps={{
                style: { backgroundColor: baseColors.bluesky },
              }}
            >
              {items.map((item, index) => (
                <Tab
                  label={item}
                  style={{
                    backgroundColor:
                      value === index
                        ? baseColors.bluesky
                        : baseColors.blue.dark,
                    fontSize: "12px",
                    fontWeight: 900,
                    color: value === index ? baseColors.blue.dark : "white",
                    width: "75vw",
                    height: "40px",
                    fontFamily: "Ricard-Light",
                  }}
                  onClick={() => scrollToSection(index)}
                />
              ))}
            </Tabs>
          </div>
        )}
        <span id={"section1"}></span>
      </Box>
      <Box
        id={"1"}
        data-section
        sx={{ minHeight: "430px", display: "flex", justifyContent: "center" }}
      >
        <Box className={contentOne}>
          <Box textAlign={"left"}>
            <CustomText
              fontSize={size}
              fontWeight={900}
              textTransform={"uppercase"}
              fontFamily="Ricard-Black"
            >
              RICARD 3.0
            </CustomText>
            <div style={{ marginBottom: "25px", marginTop: "10px", textAlign: isMobile ? "left" : "justify" }}>
              <CustomText
                fontSize={"20px"}
                fontWeight={300}
                fontFamily="Ricard-Black"
              >
                UNE INITIATIVE WEB3 REINVENTANT LE MODE DE SERVICE DE RICARD®,
                EN COLLABORATION AVEC L’ARTISTE CONTEMPORAIN NEIL BELOUFA
              </CustomText>
            </div>
          </Box>
          <div style={{ marginBottom: "25px", marginTop: "25px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={300}
              lineHeight={"16px"}
              fontFamily="Ricard-Light"
            >
              En 2024, Neïl Beloufa, artiste contemporain, imagine pour la
              marque Ricard six objets de service uniques intégrant les
              technologies Web3. Il s’appuie sur l’expertise du studio créatif
              technologique <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://ebb.global/", "_blank")
                }
              >
                ebb.global
              </span> pour leur donner vie.
            </CustomText>
          </div>
          <div style={{ marginBottom: "25px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={300}
              lineHeight={"16px"}
              fontFamily="Ricard-Light"
            >
              Inspiré par le service{" "}
              <span style={{ fontStyle: "italic" }}>« à la Marseillaise »</span>
              * créé par Paul Ricard dès 1932, Neïl Beloufa propose à travers
              ces objets un mode de préparation interactif du Ricard.
            </CustomText>
          </div>
          <CustomText
            fontSize={"16px"}
            fontWeight={300}
            lineHeight={"16px"}
            fontFamily="Ricard-Light"
          >
            RICARD 3.0 : une initiative inédite faisant écho à l’esprit pionnier
            & à la sensibilité esthétique du fondateur de la marque, Paul
            Ricard.
          </CustomText>
          <div style={{ marginTop: "20px" }}>
            <CustomText
              fontSize={"10px"}
              fontWeight={300}
              fontFamily="Ricard-Light"
              style={{ fontStyle: "italic" }}
            >
              *Le service « à la Marseillaise » désigne le mode de service
              recommandé par Paul Ricard dès la création de la marque en 1932 :
              1 volume de Ricard, 5 volumes d’eau et une cascade de glaçons.
            </CustomText>
          </div>
          <Box className={classes.buttonTwo}>
            {/* <Button
              style={{
                backgroundColor: isHovered
                  ? baseColors.blue.dark
                  : baseColors.yellow,
                borderRadius: 0,
                height: "38px",
                width: isMobile ? "90%" : "378px",
                marginTop: "33px",
              }}
              onClick={decouvrir}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <CustomText
                color={isHovered ? "white" : ""}
                fontSize={"16px"}
                fontWeight={700}
                textTransform={"uppercase"}
                fontFamily="Ricard-Black"
              >
                participer au projet
              </CustomText>
            </Button> */}
            <span id={"section2"}></span>
          </Box>
        </Box>
      </Box>
      <Box
        className={classes.yellowBox}
        id={"2"}
        data-section
        sx={{ minHeight: "430px", display: "flex", justifyContent: "center" }}
      >
        <Box className={contentOne}>
          <div style={{ marginBottom: "20px" }}>
            <CustomText
              fontSize={size}
              fontWeight={900}
              textTransform={"uppercase"}
              fontFamily="Ricard-Black"
            >
              <span style={{ color: "white" }}>Un projet</span> Héritage
            </CustomText>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              Derrière la marque Ricard se cache au départ un homme aux
              multiples facettes : Paul Ricard. Un entrepreneur à la fois
              pionnier et visionnaire, passionné d’art.
            </CustomText>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              Ancien élève des Beaux-Arts, Paul Ricard a suivi tout au long de
              sa vie un parcours hors normes, et a toujours su démontrer sa
              capacité à faire preuve de modernité. C’est cet esprit pionnier et
              créatif, en recherche constante de moyens novateurs pour faire
              connaître sa marque, qui a contribué à sa réussite.
            </CustomText>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              Cette vision subsiste aujourd’hui comme l’un des éléments les plus
              précieux du patrimoine laissé par Paul Ricard. La marque Ricard
              est donc encore aujourd’hui imprégnée de cette sensibilité
              avant-gardiste.
            </CustomText>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              Ainsi, ce projet en collaboration avec l’artiste Neïl Beloufa et
              le studio <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://ebb.global/", "_blank")
                }
              >
                ebb.global
              </span>, qui revisite le rituel de service de manière
              interactive en utilisant les technologies Web3, est une façon de
              rendre hommage au fondateur de la marque Ricard en perpétuant tous
              les aspects de son héritage.
            </CustomText>
          </div>
          <Box
            sx={{
              marginLeft: isMobile ? "10px" : "none",
              marginRight: "10px",
              width: isMobile ? "95%" : "none",
              marginBottom: isMobile ? "10px" : "10px",
            }}
          >
            <div
              style={{
                display: "inline-block",
                width: "100%",
              }}
            >
              <CustomText
                fontSize={isFold ? "16px" : "25px"}
                fontWeight={900}
                fontFamily="RicardScripte-Regular"
                lineHeight={"30px"}
                style={{ zIndex: 1 }}
              >
                « C’est le monde qui change, il faut évoluer. Et chez Ricard on
                sait qu’il faut s’adapter aux événements, au monde.&nbsp;»
              </CustomText>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CustomText
              fontSize={"20px"}
              fontWeight={400}
              textTransform={"uppercase"}
            >
              Paul Ricard
            </CustomText>
          </Box>
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <img
              src={require(`../../assets/image/Ricard.jpg`)}
              alt={`Paul Ricard`}
              className={classes.imageHead}
              style={{ margin: 0, width: isMobile ? "100%" : "100%" }}
            />
          </Box>
          <span id={"section3"}></span>
        </Box>
      </Box>
      <Box
        className={`${classes.blueBox}`}
        ref={refBlueBox1}
        id={"3"}
        data-section
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        <Box className={contentOne}>
          <div>
            <CustomText
              fontSize={isMobile ? (isFold ? "20px" : "30px") : "40px"}
              fontWeight={900}
              fontFamily="Ricard-Black"
            >
              NEÏL BELOUFA
            </CustomText>
            <CustomText
              fontSize={isMobile ? (isFold ? "15px" : "20px") : "20px"}
              fontWeight={900}
              fontFamily="Ricard-Black"
              lineHeight={isFold ? "25px" : "normal"}
            >
              Figure polyvalente de l’art contemporain
            </CustomText>
          </div>
          <img
            src={require(`../../assets/image/Neil.png`)}
            alt={`Paul Ricard`}
            className={classes.imageHead}
            style={{ marginTop: "30px", width: isMobile ? "100%" : "100%" }}
          />
          <Box
            sx={{
              marginTop: isMobile ? "10px" : "20px",
              marginBottom: "10px",
            }}
          >
            <CustomText
              fontSize={"16px"}
              fontWeight={0}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              Pour ce projet inédit - RICARD 3.0 - la marque Ricard collabore
              avec Neïl Beloufa et le studio{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://ebb.global/", "_blank")
                }
              >
                ebb.global
              </span>
              .
            </CustomText>
          </Box>
          <CustomText
            fontSize={"16px"}
            fontWeight={400}
            lineHeight={"24px"}
            fontFamily="Ricard-Light"
          >
            Artiste contemporain et réalisateur, Neïl Beloufa est notamment
            reconnu pour ses installations vidéo, ses dispositifs
            technologiques, et ses diverses expositions monographiques en France
            et à l’international. Il expose notamment au Palais de Tokyo, Paris
            en 2013-2018, au MoMA, New-york, 2016, au K11, Shanghai 2014 ; à
            Hangar Biccoca, Milan, 2021 ; ainsi qu’aux 53eme et 55eme Biennale
            de Venise en 2013 et 2019.
          </CustomText>
          <Box sx={{ marginTop: "10px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              En 2021, il cofonde <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://ebb.global/", "_blank")
                }
              >
                ebb.global
              </span> : un studio créatif décentralisé
              piloté par une équipe multidisciplinaire d'artistes, de
              commissaires, de chercheur·e·s et de développeur·euse·s.
            </CustomText>
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://ebb.global/", "_blank")
                }
              >
                ebb.global
              </span>{" "}
              explore l’intégration de nouvelles technologies aux domaines de la
              culture et contribue activement à la création de nouveaux modèles
              de distribution en adéquation avec les valeurs émergentes de notre
              société. Depuis sa création,{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://ebb.global/", "_blank")
                }
              >
                ebb.global
              </span>{" "}
              a collaboré avec des institutions culturelles, des DAOs, des
              marques et des artistes contemporains et numériques (Luma Arles,
              Secession Vienne, FWB - Friends with Benefits, Fingerprint Dao,
              Hermès, Lafayette Anticipations, Ateliers Médicis,... ).
            </CustomText>
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              Ainsi, pour cette collaboration, Neïl Beloufa s’appuie sur
              l’expertise d'<span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://ebb.global/", "_blank")
                }
              >
                ebb.global
              </span> pour donner vie à sa propre
              interprétation de l’univers de la marque Ricard et de son mode de
              service. Un véritable 4 mains, conjuguant la vision créative de
              Neïl Beloufa et l’expertise technique d’ebb, appliquée au Web3.
            </CustomText>
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              Une association riche de sens pour la marque Ricard, puisqu’elle
              fait écho à l’héritage de son fondateur Paul Ricard, créatif
              avant-gardiste.
            </CustomText>
          </Box>
          <span id={"section4"}></span>
        </Box>
      </Box>

      <Box
        className={classes.yellowBox}
        id={"4"}
        data-section
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box className={contentOne}>
          <Box textAlign={"left"}>
            <CustomText
              fontSize={size}
              fontWeight={900}
              textTransform={"uppercase"}
              lineHeight={isMobile ? "28px" : "50px"}
              fontFamily="Ricard-Black"
            >
              6 OBJETS UNIQUES,{" "}
              <span style={{ color: "white" }}>
                POUR UN MODE DE SERVICE RÉINVENTÉ
              </span>
            </CustomText>
          </Box>

          <div
            style={{
              marginTop: "20px",
            }}
          >
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              <span color="white">« 1 volume de Ricard, et 5 volumes d’eau ».</span>
            </CustomText>
          </div>
          <div style={{ marginTop: "20px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              C’est le mode de service historique, dit{" "}
              <span style={{ fontStyle: "italic" }}>« à la Marseillaise »</span>
              *, qui a inspiré Neïl Beloufa pour cette collaboration. Il imagine
              ainsi 6 objets uniques, en référence au dosage traditionnel, et
              s’appuie sur l’expertise d'<span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://ebb.global/", "_blank")
                }
              >
                ebb.global
              </span>, pour leur donner vie. Chacun est travaillé à la main, et représente une étape du
              service. Ainsi, l’un des objets, particulièrement distinctif,
              symbolise le volume de Ricard et présente des bas-reliefs qui
              diffèrent significativement des 5 autres objets qui font
              référence aux volumes d’eau.
            </CustomText>
          </div>
          <div style={{ marginTop: "10px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              Ces objets proposent un mode de service interactif du Ricard : ils
              s’animent lorsque les quantités recommandées de Ricard, puis d’eau
              ont été versées.
            </CustomText>
          </div>

          <div style={{ marginTop: "20px" }}>
            <CustomText
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"24px"}
              fontFamily="Ricard-Light"
            >
              De véritables guides au service qui peuvent être personnalisés
              grâce aux technologies Web3. Un NFT rattaché à chaque objet de
              service permet en effet l’adaptation des animations numériques qui
              guident la préparation.
            </CustomText>
          </div>

          <Box className={classes.vinette}>
            <img
              src={vinette}
              alt="Votre image"
              style={{ maxWidth: "90%", height: "auto" }}
            />
          </Box>

          <div style={{ marginTop:"10px"}}>
            <CustomText
              fontSize={"10px"}
              fontWeight={300}
              lineHeight={"16px"}
              fontFamily="Ricard-Light"
              style={{ fontStyle: "italic" }}
            >
              *Le service « à la Marseillaise » désigne le mode de service
              recommandé par Paul Ricard dès la création de la marque en 1932 :
              1 volume de Ricard, 5 volumes d’eau et une cascade de glaçons.
            </CustomText>
          </div>

          <Box className={classes.buttonOne}>
            {/* <button
              className={classes.btnOne}
              onClick={decouvrir}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ width: isMobile ? "80%" : "378px" }}
            >
              <CustomText
                color={isHovered ? "white" : ""}
                fontSize={"16px"}
                fontWeight={700}
                textTransform={"uppercase"}
                fontFamily="Ricard-Black"
              >
                participer au projet
              </CustomText>
            </button> */}
            <span id={"section5"}></span>
          </Box>
        </Box>
      </Box>

      <Box
        id={"5"}
        data-section
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box className={contentOne}>
          <Box style={{ textAlign: "left", marginBottom: "20px" }}>
            <CustomText
              fontSize={size}
              fontWeight={900}
              lineHeight={isMobile ? "28px" : "50px"}
              fontFamily="Ricard-Black"
            >
              UNE MÉCANIQUE INTERACTIVE & PARTICIPATIVE{" "}
              <span style={{ color: baseColors.yellow }}>EN DEUX ÉTAPES</span>
            </CustomText>
          </Box>
          {isMobile && (
            <Box
              sx={{
                marginTop: "10px",
                marginBottom: "10px",
                width: isMobile ? "100%" : "350px",
              }}
            >
              <div style={{ margin: "auto", width: "100%" }}>
                <Carousel autoPlay={false} indicators={true} swipe={false}>
                  {cardsMobile.map((card, index) => (
                    <div key={index}>{card}</div>
                  ))}
                </Carousel>
              </div>
            </Box>
          )}
          {isLitle
            ? !isMobile && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      width: isMobile ? "100%" : "500px",
                    }}
                  >
                    <div style={{ margin: "auto", width: "100%" }}>
                      <Carousel autoPlay={false} indicators={true} swipe={false}>
                        {cardsMobile.map((card, index) => (
                          <div key={index}>{card}</div>
                        ))}
                      </Carousel>
                    </div>
                  </Box>
                </Box>
              )
            : !isMobile && (
                <Box
                  sx={{
                    marginBottom: "10px",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <div className="slider-containerZ">
                    <Swiper
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      spaceBetween={20}
                      slidesPerView={2}
                      loop={true}
                      navigation={false}
                      pagination={{
                        type: "bullets",
                        clickable: true,
                        dynamicBullets: 2,
                      }}
                      scrollbar={{ draggable: true }}
                      onSwiper={(swiper: any) => console.log(swiper)}
                      onSlideChange={() => console.log("slide change")}
                    >
                      {cards.map((card, index) => (
                        <SwiperSlide>
                          <div
                            key={index}
                            style={{
                              marginRight: "5px",
                            }}
                          >
                            {card}
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </Box>
              )}
              <span id={"section6"}></span>
        </Box>
      </Box>

      <Box id={"6"} data-section>
        <Box sx={{ background: baseColors.bluesky }}>
          <Box
            className={classes.imageCMC}
            sx={{
              background: `url(${FONDCOULEURCMC})`,
              height: isMobile ? "155px" : "auto",
              paddingTop: !isMobile ? "70px" : "40px",
              paddingBottom: !isMobile ? "70px" : "40px",
            }}
          >
            <img
              src={require(`../../assets/image/CmC_Blanc.png`)}
              alt="Overlay"
              style={{
                objectFit: "cover",
                width: isMobile ? "80%" : "500px",
                height: isMobile ? "auto" : "none",
              }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              className={contentOne}
              sx={{ marginBottom: 0 }}
              textAlign={"left"}
            >
              <CustomText
                fontSize={size}
                fontWeight={900}
                fontFamily="Ricard-Black"
              >
                <span style={{ color: "white" }}>
                  UN PROJET RICHE DE SENS, EN PARTENARIAT AVEC{" "}
                </span>{" "}
                CLEAN MY CALANQUES
              </CustomText>
              <div style={{ marginTop: "30px" }}>
                <CustomText
                  fontSize={"16px"}
                  fontWeight={400}
                  lineHeight={"24px"}
                  fontFamily="Ricard-Light"
                >
                  Le projet RICARD 3.0 est une façon de rendre hommage au
                  fondateur de la marque, Paul Ricard, en perpétuant tous les
                  aspects de son héritage.
                </CustomText>
              </div>
              <div style={{ marginTop: "20px" }}>
                <CustomText
                  fontSize={"16px"}
                  fontWeight={400}
                  lineHeight={"24px"}
                  fontFamily="Ricard-Light"
                >
                  Marseillais d’origine, il se montra toute sa vie durant fier
                  représentant des terres qui les ont vus naître, lui et sa
                  marque. Il s’investit donc fermement dans la protection de
                  l’environnement provençal.
                </CustomText>
              </div>
              <div style={{ marginTop: "20px" }}>
                <CustomText
                  fontSize={"16px"}
                  fontWeight={400}
                  lineHeight={"24px"}
                  fontFamily="Ricard-Light"
                >
                  Ainsi, ce projet soutient Marseille et son environnement
                  naturel, chers à Paul Ricard, puisque tous les bénéfices des
                  ventes seront intégralement reversés à Clean My Calanques, une
                  association marseillaise qui oeuvre pour préserver la cité
                  phocéenne.
                </CustomText>
              </div>

              <Box
                className={classes.buttonOne}
                style={{ marginBottom: "40px" }}
              >
                <button
                  className={classes.btnOne}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{ width: isMobile ? "80%" : "378px" }}
                  onClick={() => handleButtonClickVente()}
                >
                  <CustomText
                    color={isHovered ? "white" : ""}
                    fontSize={"16px"}
                    fontWeight={700}
                    textTransform={"uppercase"}
                    fontFamily="Ricard-Black"
                  >
                    Participer à la vente
                  </CustomText>
                </button>
              </Box>
            </Box>
          </Box>
          <span id={"section7"}></span>
        </Box>
      </Box>
      <Box
        className={classes.blueBox}
        ref={refBlueBox2}
        id={"7"}
        sx={{ display: "flex", justifyContent: "center" }}
        data-section
      >
        <Box
          className={contentOne}
          sx={{
            width: "100%",
            boxSizing: "border-box",
            marginBottom: 0,
          }}
        >
          <Box width={"100%"} sx={{ marginBottom: "70px" }}>
            <CustomText
              color={"white"}
              fontSize={size}
              fontWeight={900}
              fontFamily="Ricard-Black"
            >
              FAQ
            </CustomText>
            <div style={{ marginTop: "30px", textAlign: "left" }} onClick={e => toggleSection(0,e)}>
              <CustomText
                color="white"
                fontSize={"16px"}
                fontWeight={900}
                fontFamily="Ricard-Black"
              >
                <span className={classes.highlightedText}>
                  Qu’est-ce qu’un NFT&nbsp;?
                </span>
              </CustomText>
            </div>
            {isOpen[0] && (
              <div style={{ animation: "fadeIn 0.5s ease-in-out" }}>
                <div style={{ marginTop: "20px",textAlign: isMobile ? "left": "justify" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Un NFT (Non Fungible Token, ou Jeton Non Fongible) est un
                    actif numérique unique accordant des droits, de propriété
                    par exemple, sur un objet ou un service réel ou virtuel (ce
                    peut être un diplôme, un contrat, un certificat
                    d’authenticité, mais aussi une place de concert, une œuvre
                    d’art…).
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Le concept d’unicité y est clé. En effet, chaque jeton étant
                    garanti non fongible, et donc unique, c’est ce qui lui donne
                    de la valeur.
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"10px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                    style={{ fontStyle: "italic" }}
                  >
                    NB : à l’inverse d’un dollar ou d’un euro par exemple, que
                    l’on peut échanger contre un autre dollar ou euro
                    strictement similaire (et donc fongible), un NFT est unique.
                    Il ne peut donc pas être échangé contre un autre NFT d’une
                    valeur strictement similaire : on parle de non-fongibilité.
                  </CustomText>
                </div>
              </div>
            )}

            <div style={{ marginTop: "30px",textAlign: "left" }} onClick={e => toggleSection(1,e)}>
              <CustomText
                color="white"
                fontSize={"16px"}
                fontWeight={900}
                fontFamily="Ricard-Black"
              >
                <span className={classes.highlightedText}>
                  Qu’est-ce que la blockchain, et pourquoi avoir choisi Polygon&nbsp;?
                </span>
              </CustomText>
            </div>
            {isOpen[1] && (
              <>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Une blockchain, ou chaîne de blocs, est une technologie de
                    stockage de données dit « distribuée ». Les informations
                    envoyées par les utilisateurs sont vérifiées et groupées par
                    blocs, formant ainsi une chaîne. L’ensemble est ensuite
                    sécurisé par un processus de cryptographie, sécurisant ainsi
                    chaque bloc et les informations qu’il contient.
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Toutes les informations stockées dans la blockchain sont
                    ainsi ultra sécurisées, infalsifiables, et visibles par
                    tous.
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Particulièrement, dans le cadre de ce projet, il a été
                    choisi de travailler sur la blockchain Polygon. Cette
                    blockchain permet des activités compatibles avec la chaîne
                    Ethereum tout en réduisant les latences et les frais de gaz
                    liés à la large exploitation de celle-ci.
                  </CustomText>
                </div>
              </>
            )}

            <div style={{ marginTop: "30px", textAlign: "left" }} onClick={e => toggleSection(2,e)}>
              <CustomText
                color="white"
                fontSize={"16px"}
                fontWeight={900}
                fontFamily="Ricard-Black"
              >
                <span className={classes.highlightedText}>
                  Comment participer à la mécanique&nbsp;?
                </span>
              </CustomText>
            </div>
            {isOpen[2] && (
              <>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Pour participer à la mécanique de personnalisation proposée
                    dans le contexte du Salon NFT PARIS, il convient dans un
                    premier temps de connecter son portefeuille numérique
                    (wallet) à la plateforme de personnalisation en cliquant sur
                    le bouton « CONNECTER MON PORTEFEUILLE ».
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Une fois connecté, il devient possible de sélectionner les
                    différents paramètres de service personnalisables, puis de
                    les enregistrer en générant son NFT RICARD 3.0<sup>(1)</sup>.
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Le NFT RICARD 3.0 ainsi issu est alors disponible dans le
                    portefeuille numérique (wallet) initialement connecté à la
                    plateforme. La présentation de ce dernier sur le stand
                    Ricard lors du Salon NFT PARIS permettra d’interagir avec
                    les objets de service, et donnera droit à une dégustation de
                    Ricard
                    <sup>(2)</sup>.
                  </CustomText>
                </div>
                {/* <div style={{ marginTop: "20px" }}>
                  <button
                    className={classes.btnOne}
                    onClick={decouvrir}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      width: isMobile ? "100%" : "505px",
                      height: "30px",
                    }}
                  >
                    <CustomText
                      color={isHovered ? "white" : ""}
                      fontSize={isMobile ? "12px" : "12px"}
                      fontWeight={900}
                      textTransform={"uppercase"}
                      fontFamily="RicardAllonge-Black"
                    >
                      ACCÉDER A LA PLATEFORME DE PERSONNALISATION
                    </CustomText>
                  </button>
                </div> */}
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    A la suite du Salon, seuls les acquéreurs des objets de
                    service auront accès, de façon illimitée, à la plateforme de
                    personnalisation.
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                  <button
                    className={classes.btnOne}
                    onClick={handleButtonClickVente}
                    onMouseEnter={handleMouseEnterTwo}
                    onMouseLeave={handleMouseLeaveTwo}
                    style={{
                      width: isMobile ? "90%" : "300px",
                      height: "30px",
                    }}
                  >
                    <CustomText
                      color={isHoveredTwo ? "white" : ""}
                      fontSize={isMobile ? "12px" : "12px"}
                      fontWeight={700}
                      textTransform={"uppercase"}
                      fontFamily="RicardAllonge-Black"
                    >
                      Participer à la vente
                    </CustomText>
                  </button>
                </div>
                <CustomText
                  color="white"
                  fontSize={"10px"}
                  fontWeight={400}
                  lineHeight={"24px"}
                  fontFamily="Ricard-Light"
                  style={{ fontStyle: "italic" }}
                >
                  <sup>(1)</sup>Lors de la première étape du projet, la
                  mécanique de personnalisation n’est accessible qu’une fois par
                  utilisateur.
                </CustomText>{" "}
                <CustomText
                  color="white"
                  fontSize={"10px"}
                  fontWeight={400}
                  lineHeight={"24px"}
                  fontFamily="Ricard-Light"
                  style={{ fontStyle: "italic" }}
                >
                  <sup>(2)</sup>L’offre de dégustation est sujette à la
                  disponibilité du personnel sur le stand Ricard, et dépend de
                  l'affluence sur ce dernier. Elle ne pourra pas être proposée à
                  des personnes mineures.
                </CustomText>
              </>
            )}

            <div style={{ marginTop: "30px",textAlign: "left" }} onClick={e => toggleSection(3,e)}>
              <CustomText
                color="white"
                fontSize={"16px"}
                fontWeight={900}
                fontFamily="Ricard-Black"
              >
                <span className={classes.highlightedText}>
                  A quoi sert le NFT RICARD 3.0&nbsp;?
                </span>
              </CustomText>
            </div>
            {isOpen[3] && (
              <>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    {/* Témoin de la participation de l’utilisateur à la mécanique
                    de personnalisation du mode de service imaginée par Neïl
                    Beloufa, et développée par <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open("https://ebb.global/", "_blank")
                      }
                    >
                      ebb.global
                    </span>, le NFT RICARD 3.0
                    permet d’enregistrer, dans un premier temps, les paramètres
                    de service sélectionnés par l’utilisateur<sup>(1)</sup>. */}
                    Lors du Salon NFT PARIS, le NFT RICARD 3.0 permet d’enregistrer les paramètres de service sélectionnés par l’utilisateur. Il témoigne ainsi de la participation de l’utilisateur à la mécanique de personnalisation du mode de service imaginée par Neïl Beloufa, et développée par ebb.global.
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Lorsqu’il est présenté sur le stand Ricard lors du Salon NFT
                    PARIS, il donne également droit d’interagir avec les objets
                    de service, et à une dégustation de Ricard lors du Salon NFT
                    PARIS<sup>(2)</sup>.
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    A l’expiration de cet avantage – soit après en avoir
                    bénéficié une fois ; soit à l’issue du Salon NFT PARIS le 24
                    février à 18H30 – le NFT RICARD 3.0 n’incarnera plus qu’un
                    souvenir de cette collaboration inédite & reposant sur les
                    technologies Web3.
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Par ailleurs, à l’issue du Salon, 6 autres NFTs, uniques et inspirés des paramètres de personnalisation les plus massivement choisis lors de l’événement, seront rattachés à chaque objet de service et sa bouteille en édition unique, puis vendus.<br/> Chaque NFT servira de certificat d’authenticité, attestant ainsi la propriété de chaque objet et de sa bouteille associée pour leur acquéreur. Chacun fera aussi office de clé d’accès à la plateforme de personnalisation des objets, en rendant ainsi le paramétrage exclusivement accessible aux détenteurs des NFTs. Enfin, ils serviront de tickets pour l’accès à une expérience Ricard unique et ultra exclusive...
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"10px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                    style={{ fontStyle: "italic" }}
                  >
                    <sup>(1)</sup>Lors de la première étape du projet, la
                    mécanique de personnalisation n’est accessible qu’une fois
                    par utilisateur.
                  </CustomText>
                  <CustomText
                    color="white"
                    fontSize={"10px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                    style={{ fontStyle: "italic" }}
                  >
                    <sup>(2)</sup>L'offre de dégustation est sujette à la
                    disponibilité du personnel sur le stand Ricard, et dépend de
                    l'affluence sur ce dernier. Elle ne pourra pas être proposée
                    à des personnes mineures.
                  </CustomText>
                </div>
              </>
            )}

            <div style={{ marginTop: "30px",textAlign: "left" }} onClick={e => toggleSection(4,e)}>
              <CustomText
                color="white"
                fontSize={"16px"}
                fontWeight={900}
                fontFamily="Ricard-Black"
              >
                <span className={classes.highlightedText}>
                  Comment participer à la vente des objets, de leur bouteille en édition unique, et de leur NFT&nbsp;?
                </span>
              </CustomText>
            </div>
            {isOpen[4] && (
              <>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Dans le contexte de cette collaboration avec Ricard, Neïl Beloufa imagine 6 objets uniques, en référence au dosage traditionnel : 1 volume de Ricard, et 5 volumes d’eau.<br/>Chacun est travaillé à la main, et représente une étape du service. Ainsi, l’un des objets, particulièrement distinctif, symbolise le volume de Ricard et présente des bas-reliefs qui diffèrent significativement des 5 autres objets qui font référence aux volumes d’eau. À chacun de ces objets sont associés une bouteille en édition unique, et un NFT faisant office de certificat d’authenticité, de clé d’accès à la plateforme de personnalisation des objets, et de ticket pour l’accès à une expérience unique et ultra exclusive.
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Seul l’objet symbolisant le volume de Ricard, sa bouteille en édition unique et son NFT seront proposés en vente publique, par l’intermédiaire de la maison de vente Aguttes.<br/> La vente aux enchères se déroulera le 22 avril, à l’occasion d’une vente Aguttes à Paris Expo, Porte de Versailles. Pour plus d’informations, visiter le site de la vente <a style={{color:"white"}} target="_blank" href="https://www.aguttes.com/vente/147039-tour-auto-2024-la-vente-officielle">en cliquant ici</a>.<br/><br/>
                    La vente des 5 autres objets sera traitée de manière plus directe : toute demande d’information peut donc se faire par le biais de l’adresse mail <a style={{color:"white"}} href="mailto:contact@ricard.com" target="_blank">contact@ricard.com</a>.
                  </CustomText>
                </div>
              </>
            )}

            <div style={{ marginTop: "30px",textAlign: "left" }} onClick={e => toggleSection(5,e)}>
              <CustomText
                color="white"
                fontSize={"16px"}
                fontWeight={900}
                fontFamily="Ricard-Black"
              >
                <span className={classes.highlightedText}>
                  Qu’est-ce que le Salon NFT PARIS&nbsp;?
                </span>
              </CustomText>
            </div>
            {isOpen[5] && (
              <>
                <div>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Le Salon NFT PARIS est l’un des plus gros événements Web3 du
                    monde.
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Dédié aux NFTs (non fungible tokens ou jetons non
                    fongibles), l’événement célèbre la révolution, notamment
                    créative, apportée par ces actifs numériques. Il en explore
                    les impacts & opportunités dans différents secteurs
                    (finance, art, sport, musique, médias, mode, luxe, jeux
                    vidéo…).
                  </CustomText>
                </div>
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    Le Salon rassemble ainsi des experts en blockchain, des
                    artistes, des développeurs, des professionnels de la mode &
                    des médias, mais aussi des industriels, des cabinets de
                    conseil… et propose des conférences sur une durée de deux
                    jours, du 23 au 24 février 2024.
                  </CustomText>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <button
                    className={classes.btnOne}
                    onClick={() =>
                      window.open("https://www.nftparis.xyz/", "_blank")
                    }
                    onMouseEnter={handleMouseEnterTwo}
                    onMouseLeave={handleMouseLeaveTwo}
                    style={{
                      width: isMobile ? "90%" : "300px",
                      height: "30px",
                    }}
                  >
                    <CustomText
                      color={isHoveredTwo ? "white" : ""}
                      fontSize={isMobile ? "12px" : "12px"}
                      fontWeight={900}
                      textTransform={"uppercase"}
                      fontFamily="RicardAllonge-Black"
                    >
                      EN SAVOIR PLUS SUR LE SALON
                    </CustomText>
                  </button>
                </div>
              </>
            )}

            <div style={{ marginTop: "30px", textAlign: "left" }} onClick={e => toggleSection(6,e)}>
              <CustomText
                color="white"
                fontSize={"16px"}
                fontWeight={900}
                fontFamily="Ricard-Black"
              >
                <span className={classes.highlightedText}>
                  Besoin d’une assistance technique&nbsp;?
                </span>
              </CustomText>
            </div>
            {isOpen[6] && (
              <>
                <div>
                  <CustomText
                    color="white"
                    fontSize={"14px"}
                    fontWeight={400}
                    lineHeight={"24px"}
                    fontFamily="Ricard-Light"
                  >
                    En cas de problème technique, merci d’adresser vos questions
                    à{" "}
                    <a
                      style={{ textDecoration: "none", color: "white" }}
                      href="mailto:contact@ebb.global"
                    >
                      contact@ebb.global
                    </a>
                  </CustomText>
                </div>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        className={classes.yellowBox}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Footer />
      </Box>
    </Box>
  );
};
