import React, {
  FocusEventHandler,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { Grid, TextField, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { baseColors } from "../../Constants/baseStyles";

type AppProps = {
  defaultValue?: string;
  label: string;
  placeholder: string;
  onChange: Function;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  width: any;
  height?: any;
  value?: string;
  inversed?: boolean;
  validator?: Function;
  errorMessage?: string;
  disabled?: boolean;
  multiline?: boolean;
  single?: boolean;
  idSuffix?: string;
  passwordInput?: boolean;
  defaultInputId?: string;
  onKeyPress?: Function;
  withoutLabel?: boolean;
  icon?: ReactElement;
  beforeLastIcon?: ReactElement;
  lastIcon?: ReactElement;
  phonePrefix?: string;
};
const CustomTextField = styled(TextField)`
    & .MuiInputBase-input {
      border-radius: 0;
      text-align: center;
      font-family: RicardAllonge-Black;
      color: rgb(0, 34, 108);
      font-size: 20px;
      font-weight: 400;
    },
  `;

const useStyles = makeStyles({
  input: {
    width: "100%",
    borderWidth: 0,
    borderRadius: 0,
    textAlign: "center",
    "&:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 100px #000 inset",
      "-webkit-text-fill-color": "#fff",
    },
    "& label.Mui-focused": {
      color: "transparent",
      borderWidth: 0,
      textAlign: "center",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
      borderWidth: 0,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        background: "transparent",
        borderWidth: 0,
        textAlign: "center",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
        borderWidth: 0,
      },
    },
    "& input::placeholder": {
      color: "white",
      textAlign: "center",
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: 900,
      lineHeight: "normal",
      textTransform: "uppercase",
    },
  },

  inputInversed: {
    width: "100%",
    textAlign: "center",
    "& label.Mui-focused": {
      color: baseColors.blue.dark,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 0,
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
  },
  inputInvalid: {
    marginTop: 10,
    width: "100%",
    textAlign: "center",
    "& label.Mui-focused": {
      color: "transparent",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: baseColors.grey,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: baseColors.grey,
        borderRadius: 10,
      },
      "&:hover fieldset": {
        borderColor: baseColors.grey,
      },
      "&.Mui-focused fieldset": {
        borderColor: baseColors.grey,
      },
    },
  },
});

export const CustomTextFields = ({
  defaultValue,
  onBlur,
  value,
  validator,
  height,
  multiline,
  inversed,
  errorMessage,
  label,
  placeholder,
  onChange,
  width,
  single,
  disabled,
  idSuffix,
  passwordInput,
  defaultInputId,
  onKeyPress,
  icon,
  beforeLastIcon,
  lastIcon,
  withoutLabel,
  phonePrefix,
}: AppProps) => {
  const classes = useStyles();
  const [isValid, setIsValid] = useState(false);
  //const [defaultValue, setDefaultValue] = useState(value);

  useEffect(() => {
    if (!validator) {
      setIsValid(true);
    } else {
      if (value && value?.length > 0 && !validator(value)) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [validator, value]);

  const handleKeyDown = (event: any) => {
    const isNumber = /[0-9]|Backspace/.test(event.key);
    if (!isNumber) {
      event.preventDefault();
    }
  };

  return (
    <div style={{ width: width }}>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          width: "100%",
        }}
      >
        {label !== "" && !withoutLabel && (
          <Grid direction="row" container alignItems="center">
            {!isValid && (
              <Grid item>
                <Typography
                  color={baseColors.grey}
                  style={{ marginLeft: 5, fontStyle: "italic" }}
                  fontSize={"13px"}
                >
                  ({errorMessage})
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        <CustomTextField
          defaultValue={defaultValue}
          type="number"
          onBlur={onBlur}
          value={value}
          onKeyDown={handleKeyDown}
          size="small"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChange(event.target.value)
          }
          multiline={multiline ?? false}
          placeholder={placeholder}
          className={
            isValid
              ? inversed
                ? classes.inputInversed
                : classes.input
              : classes.inputInvalid
          }
          InputProps={{
            style: {
              minHeight: height ?? 40,

              // WebkitBoxShadow: "0 0 0 1000px white inset",
            },
            type: passwordInput ? "password" : undefined,
            startAdornment: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Box mt={0.5} mr={1}>
                  {icon}
                </Box>
                {phonePrefix && (
                  <Typography
                    style={{
                      marginRight: "0.5rem",
                      color: isValid ? "#ffffff" : baseColors.grey,
                    }}
                    mt={0.5}
                  >
                    {phonePrefix}
                  </Typography>
                )}
              </div>
            ),
            endAdornment: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Box mt={0.5} mr={1}>
                  {beforeLastIcon}
                </Box>

                <Box mt={0.5} ml={1}>
                  {lastIcon}
                </Box>
              </div>
            ),
          }}
          style={{
            // WebkitBoxShadow: "0 0 0 1000px white inset",
            marginTop: !single ? 3 : 0,
            marginBottom: !single ? 20 : 0,
            textAlign: "center",
          }}
          disabled={disabled ?? false}
        />
      </div>
    </div>
  );
};
