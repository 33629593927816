import { makeStyles } from "@mui/styles";
import { baseColors } from "../../Constants/baseStyles";

const listStyles = makeStyles(() => ({
  boxOne: {
    height: "100%",
    display: "flex", 
    justifyContent: "center"
  },
  principal: {
    maxWidth: "1280px",
    marginTop: "60px",
    marginBottom: "70px",
    marginLeft: "20px",
    marginRight: "20px",
    paddingRight: "80px",
    paddingLeft: "80px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row", 
  },
  principalMobile: {
    flexDirection: "row",
    height: "calc(100vh - 71px)",
    zIndex: 0,
    position: "relative",
    backgroundColor: baseColors.yellow,
  },
  title: {
    width: "40%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "80px"
  },
  titleText: {
    
  },
  content: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    zIndex: 1,
    marginTop: 8,
  },
  contentTwo: {
    height: "calc(100vh - 91px)",
    backgroundColor: "red",
  },
  boxImage: {
    height: "70vh",
    width: "calc(70vh * 600 / 1024)",
    border: "10px solid #FFF",
    marginTop: "2px",
  },
  btnOne: {
    width: "calc(76vh * 600 / 1024)",
    height: "45px",
    backgroundColor: baseColors.blue.dark,
    borderRadius: 0,
    marginTop: "10px",
    border: "none",
    transition: "background-color 0.3s ease",
    cursor: "pointer",
    zIndex: 1002,
    position: "relative",
  },
  buttonOne: {
    justifyContent: "center",
    display: "flex",
    position: "fixed",
    bottom: "41px",
    zIndex: 1001,
  },
  titleTwo: {
    marginTop: "30px",
    marginLeft: "22px",
  },
  boxContentGalery: {
    maxWidth: "1280px",
    flexDirection: "column",
    display: "flex",
    zIdex: 999,
  },
  boxGalery: {
    justifyContent: "center",
    marginTop: "30px",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    gap: "10px",
    justifyItems: "center", 
    alignItems: "center", 
  },
  boxGaleryMobile: {
    marginTop: "30px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(6, 1fr)",
    gap: "10px",
    justifyItems: "center", 
    alignItems: "center",
    marginBottom: '76px',
  },
  boxImageGalery: {
    border: "10px",
    borderColor: baseColors.yellow,
    marginTop: "2px",
    backgroundColor: baseColors.yellow,
    borderStyle: 'solid',
  },
  boxImageGaleryMobile: {
    height: "30vh",
    width: "40vw",
    border: "10px solid",
    borderColor: baseColors.yellow,
    marginTop: "2px",
  },
}));

export default listStyles;
