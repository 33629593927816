import React from "react";

export const iconArrowLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="27"
      viewBox="0 0 12 27"
      fill="none"
    >
      <path
        d="M11 1L1 13.5L11 26"
        stroke="#00226C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default iconArrowLeft;
