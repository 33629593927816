import { makeStyles } from "@mui/styles";
import { baseColors } from "../../../Constants/baseStyles";

const textureStyles = makeStyles(() => {
  return {
    boxOne: {
      width: "298px",
      height: "90px",
      border: "8px solid",
      borderColor: baseColors.yellow,
      background: "#FFF",
      marginTop: "10px",
      flexDirection: "column",
      display: "flex",
      alignItems: "center"
    },
    boxOneMobile: {
      width: "calc(100% - 52px)",
      height:"170px",
      border: "8px solid",
      borderColor: baseColors.yellow,
      background: "#FFF",
      marginTop: "10px",
      flexDirection: "column",
      display: "flex",
      alignItems: "center"
    },
    boxTube: {
      width: "60%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    boxTitle: {
      width: "40%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 7,
    },
    image: {
      width: "40px",
      height: "57px",
      cursor: "pointer",
      border: "2px solid transparent",
      borderRadius: "5px",
    },
    imageMobile: {
      marginTop: "20px",
      width: "45px",
      cursor: "pointer",
      border: "2px solid transparent",
      borderRadius: "5px",
    }
  };
});

export default textureStyles;
