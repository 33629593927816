import { Typography } from "@mui/material";
import React from "react";
import { baseColors, baseFontFamily } from "../../Constants/baseStyles";

interface CustomTextProps {
  children: any;
  fontSize: string;
  fontStyle?: string;
  fontWeight: number;
  color?: string;
  numberOfLines?: number;
  fontFamily?: string;
  textShadow?:string;
  border?: string;
  borderColor?: string;
  textTransform?: any;
  lineHeight?:any;
  style?: any;
}

export const CustomText: React.FC<CustomTextProps> = ({
  fontSize,
  children,
  fontStyle,
  fontWeight,
  fontFamily,
  numberOfLines,
  color,
  textShadow,
  textTransform,
  lineHeight,
  style
}: CustomTextProps) => {
  
  return (
    <Typography
      style={{
        color: color || baseColors.blue.dark,
        fontSize: fontSize,
        fontWeight: fontWeight,
        lineHeight: lineHeight || 'normal',
        fontStyle: fontStyle || 'normal',
        fontFamily: fontFamily || baseFontFamily.ricard,
        textShadow: textShadow || 'none',
        textTransform: textTransform || 'none',
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};
