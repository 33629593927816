import { Box, Button, useMediaQuery } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import BackStyles from "./BackStyles";
import { baseColors } from "../../../Constants/baseStyles";
import { CustomText } from "../../../Components/CustomeText/CustomText";

interface FondProps {
    setCurrentVideo?: any;
  }

const FondNew : FC<FondProps> = (props) => {
  const { setCurrentVideo } = props;
  const classes = BackStyles();
  const [activeBox, setActiveBox] = useState(1);
  const isMobile = useMediaQuery("(max-width:800px)");

  const handleBoxClick = (boxNumber: any) => {
    setActiveBox(boxNumber);
  };
  useEffect(() => {
    setCurrentVideo(activeBox - 1);
  }, [activeBox])
  

  const createButtons = (start: any) => {
    return Array.from({ length: 3 }).map((_, index) => (
      <Box
        key={index}
        onClick={() => handleBoxClick(start + index + 1)}
        sx={{
          cursor: "pointer",
          width: isMobile ? "38px":"30px",
          height: isMobile ? "38px":"30px",
          backgroundColor:
            activeBox === start + index + 1
              ? baseColors.yellow
              : baseColors.blue.dark,
          display: "flex",
          border: "1px solid #00226C",
          borderRadius: "3px",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <CustomText
          color="white"
          fontSize={"14px"}
          fontWeight={900}
          fontFamily="Ricard-Black"
        >
          {start + index + 1}
        </CustomText>
      </Box>
    ));
  };

  return (
    <Box className={isMobile ? classes.boxContentMobile : classes.boxContent}>
      <div
        style={{
          marginTop: "5px",
          height: "15px",
          width: "75%",
          display: "flex",
          justifyContent: "center",
          marginBottom: isMobile ? "40px":"20px",
          textAlign: "center",
          position: "relative",
        }}
      >
        <CustomText
          color="white"
          fontSize={"14px"}
          fontWeight={900}
          fontFamily="Ricard-Black"
          style={{ position: "absolute", zIndex: 1 }}
        >
          Texture du fond
        </CustomText>
        <CustomText
          fontSize={"14px"}
          fontWeight={900}
          fontFamily="RicardOmbre-Black"
          style={{ position: "absolute", zIndex: 2 }}
        >
          Texture du fond
        </CustomText>
      </div>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "space-around", gap: "3px" }}
        >
          {createButtons(0)}
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "space-around", gap: "3px" }}
        >
          {createButtons(3)}
        </Box>
      </Box>
    </Box>
  );
};

export default FondNew;
