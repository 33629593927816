import React, { FC } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { baseColors, baseFontFamily } from "../../Constants/baseStyles";
import { CustomText } from "../CustomeText/CustomText";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: 0,
  bgcolor: "white",
};

interface CustomModalProps {
  toggleModal: any;
  open: boolean;
  children: any;
  title?: string;
  width?: any;
  height?: any;
  subheader?: any;
  footer?: any;
  disableBackdropClick?: boolean;
  clearFormData?: any;
  withMinHeight?: boolean;
}

const CustomModal: FC<CustomModalProps> = (props) => {
  const {
    open,
    toggleModal,
    children,
    title,
    width,
    height,
    subheader,
    footer,
    clearFormData,
    disableBackdropClick,
    withMinHeight,
  } = props;

  const handleClose = () => {
    toggleModal();
    clearFormData?.();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => (disableBackdropClick ? {} : handleClose())}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            ...style,
          }}
        >
          <Box
            sx={{
              marginTop: 0,
              background: baseColors.yellow,
              width: "100%",
              height: "45px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomText
              color="white"
              fontSize={"25px"}
              fontWeight={900}
              textTransform={"uppercase"}
              fontFamily="Ricard-Black"
              style={{position: "absolute", zIndex:1}}
            >
              Titre
            </CustomText>
            <CustomText
              fontSize={"25px"}
              fontWeight={900}
              textTransform={"uppercase"}
              fontFamily="RicardOmbre-Black"
              style={{position: "absolute", zIndex:2}}
            >
              Titre
            </CustomText>
          </Box>
          <Box sx={{ marginTop: 1, marginBottom: 1 }}>{subheader}</Box>
          <Box
            style={{
              overflow: "hidden",
              overflowY: "auto",
              width: width ?? 400,
              height: height ?? undefined,
              minHeight: withMinHeight ? 200 : undefined,
              textAlign: "center",
            }}
          >
            {children}
          </Box>
          {footer}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
