import PropTypes from "prop-types";
import { useEffect,  useReducer } from "react";
import  { CustomText }  from "../../../Components/CustomeText/CustomText";
import "./style.css";
import { useMediaQuery } from "@mui/material";

export const Liquide = ({ property1, setLiquide }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "toolbox-liquide-1",
  });
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (state.property1 === "toolbox-liquide-1") {
      setLiquide(0);
    } else if (state.property1 === "toolbox-liquide-2") {
      setLiquide(1);
    } else if (state.property1 === "toolbox-liquide-3") {
      setLiquide(2);
    } else if (state.property1 === "toolbox-liquide-4") {
      setLiquide(3);
    } else if (state.property1 === "toolbox-liquide-5") {
      setLiquide(4);
    } else if (state.property1 === "toolbox-liquide-6") {
      setLiquide(5);
    }
  }, [state.property1]);

  return (
    <div className="liquide" style={{ rotate: isMobile ? "-90deg" : "none" }}>
      <div className={isMobile? "couleur-du-liquideMobile" : "couleur-du-liquide"} >
        <div className={`overlap-group ${state.property1}`}>
          <div className="group">
            <div className="text-wrapper">
            <div style={{marginTop: "22px", height:"15px", width: "154px"}}>
            <CustomText
              color="white"
              fontSize={"13px"}
              fontWeight={900}
              fontFamily="Ricard-Black"
              style={{position: "absolute", zIndex:1}}
            >
              Couleur du liquide
            </CustomText>
            <CustomText
              fontSize={"13px"}
              fontWeight={900}
              fontFamily="RicardOmbre-Black"
              style={{position: "absolute", zIndex:1}}
            >
              Couleur du liquide
            </CustomText>
            </div>
            </div>
          </div>
          <div className="jaune">
            {state.property1 === "toolbox-liquide-1" && (
              <>
                <div className="div">Bleu</div>
                <img className="vector" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-11-6.svg" />
                <div className="ellipse" />
              </>
            )}

            {[
              "toolbox-liquide-2",
              "toolbox-liquide-3",
              "toolbox-liquide-4",
              "toolbox-liquide-5",
              "toolbox-liquide-6",
            ].includes(state.property1) && (
              <div className="overlap-group-2">
                <div
                  className="jaune-2"
                  onClick={() => {
                    dispatch("click_64");
                  }}
                >
                  <div className="div">Bleu</div>
                  <img className="vector" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-11-6.svg" />
                  <div className="ellipse-2" />
                </div>
                <div
                  className="bleu"
                  onClick={() => {
                    dispatch("click_99");
                  }}
                >
                  <div className="text-wrapper-3">Bleu frais</div>
                  <img className="img" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-11-6.svg" />
                  <div className="ellipse-3" />
                </div>
                <div
                  className="bleu-frais"
                  onClick={() => {
                    dispatch("click_72");
                  }}
                >
                  <div className="text-wrapper-2">Jaune</div>
                  <img className="vector" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-11-6.svg" />
                  <div className="ellipse-4" />
                </div>
                <div
                  className="transparent"
                  onClick={() => {
                    dispatch("click_76");
                  }}
                >
                  <div className="text-wrapper-6">Ricard</div>
                  <img className="vector-2" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-9-6.svg" />
                  <div className="ellipse-5" />
                </div>
                <div
                  className="rose"
                  onClick={() => {
                    dispatch("click_80");
                  }}
                >
                  <div className="text-wrapper-5">Rouge</div>
                  <img className="vector" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-11-6.svg" />
                  <div className="ellipse-6" />
                </div>
                <div
                  className="vert"
                  onClick={() => {
                    dispatch("click_84");
                  }}
                >
                  <div className="text-wrapper-6">Vert</div>
                  <img className="vector-3" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-7-6.svg" />
                  <div className="ellipse-7" />
                </div>
              </div>
            )}
          </div>
          {state.property1 === "toolbox-liquide-1" && (
            <>
              <div
                className="bleu-2"
                onClick={() => {
                  dispatch("click");
                }}
              >
                <div className="text-wrapper-3">Bleu frais</div>
                <img className="vector" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-11-6.svg" />
                <div className="ellipse-8" />
              </div>
              <div
                className="bleu-frais-2"
                onClick={() => {
                  dispatch("click_43");
                }}
              >
                <div className="text-wrapper-2">Jaune</div>
                <img className="vector" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-11-6.svg" />
                <div className="ellipse-9" />
              </div>
              <div
                className="transparent-2"
                onClick={() => {
                  dispatch("click_47");
                }}
              >
                <div className="text-wrapper-6">Ricard</div>
                <img className="vector-2" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-9-6.svg" />
                <div className="ellipse-8" />
              </div>
              <div
                className="rose-2"
                onClick={() => {
                  dispatch("click_51");
                }}
              >
                <div className="text-wrapper-5">Rouge</div>
                <img className="vector" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-11-6.svg" />
                <div className="ellipse-8" />
              </div>
              <div
                className="vert-2"
                onClick={() => {
                  dispatch("click_55");
                }}
              >
                <div className="text-wrapper-6">Vert</div>
                <img className="vector-3" alt="Vector" src="https://c.animaapp.com/wH9DvtXh/img/vector-7-6.svg" />
                <div className="ellipse-8" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "toolbox-liquide-1") {
    switch (action) {
      case "click":
        return {
          property1: "toolbox-liquide-2",
        };

      case "click_43":
        return {
          property1: "toolbox-liquide-3",
        };

      case "click_47":
        return {
          property1: "toolbox-liquide-4",
        };

      case "click_51":
        return {
          property1: "toolbox-liquide-5",
        };

      case "click_55":
        return {
          property1: "toolbox-liquide-6",
        };
    }
  }

  if (state.property1 === "toolbox-liquide-2") {
    switch (action) {
      case "click_72":
        return {
          property1: "toolbox-liquide-3",
        };

      case "click_76":
        return {
          property1: "toolbox-liquide-4",
        };

      case "click_80":
        return {
          property1: "toolbox-liquide-5",
        };

      case "click_84":
        return {
          property1: "toolbox-liquide-6",
        };
    }
  }

  if (state.property1 === "toolbox-liquide-3") {
    switch (action) {
      case "click_99":
        return {
          property1: "toolbox-liquide-2",
        };

      case "click_76":
        return {
          property1: "toolbox-liquide-4",
        };

      case "click_80":
        return {
          property1: "toolbox-liquide-5",
        };

      case "click_84":
        return {
          property1: "toolbox-liquide-6",
        };
    }
  }

  if (state.property1 === "toolbox-liquide-4") {
    switch (action) {
      case "click_99":
        return {
          property1: "toolbox-liquide-2",
        };

      case "click_72":
        return {
          property1: "toolbox-liquide-3",
        };

      case "click_80":
        return {
          property1: "toolbox-liquide-5",
        };

      case "click_84":
        return {
          property1: "toolbox-liquide-6",
        };
    }
  }

  if (state.property1 === "toolbox-liquide-5") {
    switch (action) {
      case "click_99":
        return {
          property1: "toolbox-liquide-2",
        };

      case "click_72":
        return {
          property1: "toolbox-liquide-3",
        };

      case "click_76":
        return {
          property1: "toolbox-liquide-4",
        };

      case "click_84":
        return {
          property1: "toolbox-liquide-6",
        };
    }
  }

  if (state.property1 === "toolbox-liquide-6") {
    switch (action) {
      case "click_99":
        return {
          property1: "toolbox-liquide-2",
        };

      case "click_72":
        return {
          property1: "toolbox-liquide-3",
        };

      case "click_76":
        return {
          property1: "toolbox-liquide-4",
        };

      case "click_80":
        return {
          property1: "toolbox-liquide-5",
        };
    }
  }

  switch (action) {
    case "click_64":
      return {
        ...state,
        property1: "toolbox-liquide-1",
      };
  }

  return state;
}

Liquide.propTypes = {
  property1: PropTypes.oneOf([
    "toolbox-liquide-2",
    "toolbox-liquide-3",
    "toolbox-liquide-1",
    "toolbox-liquide-4",
    "toolbox-liquide-6",
    "toolbox-liquide-5",
  ]),
};
