import React, { FC } from 'react';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { RiCheckboxBlankFill, RiCheckboxFill } from "react-icons/ri"; 
import { baseColors } from '../../Constants/baseStyles';


interface IProps{
    checked: any, 
    onChange: any
}

const CustomCheckbox:FC<IProps> = ({ checked, onChange }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    }}
    onClick={onChange}
  >
    <Checkbox
      checked={checked}
      onChange={() => {}}
      icon={<CheckBoxOutlineBlankIcon />}
      checkedIcon={<CheckBoxIcon style={{ color: 'lightblue' }} />}
      style={{ display: 'none' }}
    />
    {checked ? (
      <RiCheckboxFill style={{ color: baseColors.blue.dark, height: '1.5em', width: '1.5em' }} />
    ) : (
      <RiCheckboxBlankFill style={{ color: 'white', height: '1.5em', width: '1.5em' }} />
    )}
  </div>
);

export default CustomCheckbox;