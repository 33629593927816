const ClearStorage = async () => {
    try {
        await sessionStorage.clear();
    } catch (error) {
        throw new Error('Error clearing sessionStorageService');
    }
};

export const LocalStorageService = {
    ClearStorage,
};
