import { FC, ReactNode, useState } from "react";
import AuthProvider from "./AuthProvider";
import  TextColorProvider  from "./TextColorProviders";

interface ProviderProps {
  children: ReactNode;
}

export const Provider: FC<ProviderProps> = ({ children }) => (
  <AuthProvider>
    <TextColorProvider>{children}</TextColorProvider>
  </AuthProvider>
);
