import { makeStyles } from "@mui/styles";
import { baseColors } from "../../../Constants/baseStyles";

const BackStyles  = makeStyles(() => {


  // width: 127px;
  //   border: 8px solid;
  //   height: 200px;
  //   display: flex;
  //   background: #FFF;
  //   align-items: center;
  //   margin-left: 10px;
  //   border-color: #FFC700;
  //   flex-direction: column;
  //   margin-top: 10px;
  return{
    boxContent: {
      width: "109px",
      height: "111px",
      border: "8px solid",
      borderColor: baseColors.yellow,
      background: "#FFF",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
    },
    boxContentMobile: {
      width: "136px",
      height: "200px",
      border: "8px solid",
      borderColor: baseColors.yellow,
      background: "#FFF",
      flexDirection: "column",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "10px",
      marginTop: "10px",
    }
  }
    
});

export default BackStyles