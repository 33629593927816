import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Slide, useMediaQuery } from "@mui/material";
import personalizedStyles from "./PersonalizedStyles";
import { CustomText } from "../../Components/CustomeText/CustomText";
import { baseColors } from "../../Constants/baseStyles";
import IconPlus from "../../assets/icone/iconPlus";
import IconArrowLeft from "../../assets/icone/iconArrowLeft";
import IconArrowRight from "../../assets/icone/iconArrowRight";
import { Liquide } from "./Liquide";
import { Texture } from "./Texture/Texture";
import { useNavigate } from "react-router-dom";
import IconPlusMobile from "../../assets/icone/iconPlusMobile";
import IconArrowLeftMob from "../../assets/icone/iconArrowLeftMob";
import IconArrowRightMob from "../../assets/icone/iconArrowRightMob";
import CustomModal from "../../Components/CustomModal/CustomModal";
import { CustomTextFields } from "../../Components/Input/CustomTextField";
import FondNew from "./Fond/FondNew";
import back from "../../assets/image/Fond_page_connexion.jpg";


export const Personalized = () => {
  const classes = personalizedStyles();
  const [showDiv, setShowDiv] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0); // Forme du tube : LIQ
  const [currentVideo, setCurrentVideo] = useState(0); // Texture de fond : BG
  const [currentLiquide, setCurrentLiquide] = useState(0); // Couleur du liquide : COL
  const [currentBox, setCurrentBox] = useState(0); // animation ?
  const [image, setImage] = useState(0); // Couleur du tube : TUB

  const [userId, setUserId] = useState("");

  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef(
    new Audio(require("../../assets/image/sfx/glou.wav"))
  );
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredOne, setIsHoveredOne] = useState(false);
  const [nftTitle, setNftTitle] = useState("");
  const isMobile = useMediaQuery("(max-width:800px)");
  const toolBox = isMobile ? classes.boxOneMobile : classes.boxOne;
  const saveBox = isMobile ? classes.boxThreeMobile : classes.boxThree;
  const imageBox = isMobile ? classes.boxImageMobile : classes.boxImage;
  const tubeBox = isMobile ? classes.tubeMobile : classes.tube;
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [side, setSide] = useState("f");

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnterOne = () => {
    setIsHoveredOne(true);
  };

  const handleMouseLeaveOne = () => {
    setIsHoveredOne(false);
  };

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/api/verify.php").then(res => res.text()).then(response => {
      if(response === "FALSE"){
        window.location.href = process.env.PUBLIC_URL + "/phpage/connect.php"
      }else{
        // if(response.length <= 99){
          setUserId(response);
        // }
      }
    })
  },[])

  useEffect(() => {
    const handlePageChange = () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
    const unlisten = () => {
      handlePageChange();
    };
    return unlisten;
  }, [navigate]);

  const handlePlayPause = () => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.play();
      audioRef.current.play();
    }
    isMobile && setChecked((prev) => !prev);
  };

  const handleDivToggle = () => {
    setShowDiv((prev) => !prev);
  };

  const handleImageSelection = (index: any) => {
    setCurrentIndex(index);
  };

  const handleList = () => {
    try{
      fetch(process.env.PUBLIC_URL + "/api/dataToRic.php?BG="+currentVideo+"&LIQ="+currentIndex+"&COL="+currentLiquide+"&TUB="+image).then(res => res.text()).then(response => {
        console.log("response :")
        console.log(response)
        // if(response === "TRUE"){
          navigate("/list");
        // }
      })
    } catch (e) {
      console.log("error :")
      console.log(e)
      navigate("/list");
    }
  };

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const Tube = () => {
    return (
      <Box className={tubeBox}>
        <Box display={"flex"} justifyContent={"center"}>
          <div
            style={{
              marginTop: "5px",
              height: "15px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomText
              color="white"
              fontSize={"13px"}
              fontWeight={900}
              fontFamily="Ricard-Black"
              style={{ position: "absolute", zIndex: 2 }}
            >
              Forme du tube
            </CustomText>
            <CustomText
              fontSize={"13px"}
              fontWeight={900}
              fontFamily="RicardOmbre-Black"
              style={{ position: "absolute", zIndex: 2 }}
            >
              Forme du tube
            </CustomText>
          </div>
        </Box>
        <div className={classes.selectTube}>
          <div
            onClick={() => setCurrentIndex(Math.max(currentIndex - 1, 0))}
            style={{
              width: "25px",
              height: "25px",
              cursor: "pointer",
              marginRight: "auto",
            }}
          >
            <IconArrowLeft />
          </div>
          <div>
            <img
              src={require(`../../assets/image/tub/${currentIndex}.png`)}
              alt={`Image ${currentIndex}`}
              style={{
                width: "auto",
                height: "13vh",
                margin: "25px",
                marginTop: 0,
                marginBottom: 0,
              }}
            />
          </div>
          <div
            onClick={() => setCurrentIndex(Math.min(currentIndex + 1, 5))}
            style={{
              width: "25px",
              height: "25px",
              marginLeft: "auto",
              cursor: "pointer",
            }}
          >
            <IconArrowRight />
          </div>
        </div>
        <Box className={classes.selectTubeBottom}>
          {[...Array(6)].map((_, index) => (
            <img
              key={index}
              src={require(`../../assets/image/tub/${index}.png`)}
              alt={`Image ${index}`}
              style={{
                width: "33.757px",
                height: "40px",
                margin: "5px",
                cursor: "pointer",
                borderRadius: "5px",
                background: currentIndex === index ? "#51E0FF" : "none",
                paddingTop: 5,
                paddingBottom: 5,
              }}
              onClick={() => handleImageSelection(index)}
            />
          ))}
        </Box>
      </Box>
    );
  };
  const handleChangeText = (event: any) => {
    setNftTitle(event.target.nftTitle);
  };

  const ContentModal = () => {
    return (
      <div>
        <Box>
          <input
            style={{
              border: "none",
              width: "94%",
              height: "240px",
              marginBottom: "10px",
            }}
            type="text"
            value={nftTitle}
            onChange={handleChangeText}
          />
        </Box>
        <Box sx={{ bottom: 0 }}>
          <Button
            style={{
              backgroundColor: baseColors.yellow,
              borderRadius: 0,
              width: "95%",
              height: "40px",
            }}
            onClick={handleClose}
          >
            <CustomText
              color="white"
              fontSize={"25px"}
              fontWeight={900}
              textTransform={"uppercase"}
              fontFamily="Ricard-Black"
              style={{ position: "absolute", zIndex: 1 }}
            >
              Ok
            </CustomText>
            <CustomText
              fontSize={"25px"}
              fontWeight={900}
              textTransform={"uppercase"}
              fontFamily="RicardOmbre-Black"
              style={{ position: "absolute", zIndex: 2 }}
            >
              Ok
            </CustomText>
          </Button>
        </Box>
      </div>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundImage: `url(${back})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        className={!isMobile ? classes.contentBox : classes.contentBoxMobile}
      >
        <Box className={toolBox}>
          <Box display="flex" position="relative">
            <Box
              style={{
                backgroundColor: baseColors.yellow,
                borderRadius: 0,
                height: "40px",
                width: "240px",
                margin: "10px",
                display: "flex",
                justifyContent: "center",
                marginLeft: "10px",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <CustomText
                fontSize={"24px"}
                fontWeight={900}
                textTransform={"uppercase"}
                fontFamily="Ricard-Black"
              >
                User{userId}
              </CustomText>
            </Box>
            <Button
              style={{
                backgroundColor: baseColors.yellow,
                borderRadius: 0,
                height: "40px",
                width: "40px",
                marginTop: "10px",
              }}
              onClick={handleDivToggle}
            >
              <IconPlus />
            </Button>

            <CustomModal
              toggleModal={setOpen}
              open={open}
              width={"359px"}
              height={"299px"}
            >
              <ContentModal />
            </CustomModal>

            <Slide direction="right" in={showDiv} mountOnEnter unmountOnExit>
              <div
                style={{
                  position: "absolute",
                  left: 1,
                  top: 0,
                  width: "330px",
                  height: "420px",
                  opacity: showDiv ? 1 : 0,
                  transition: "opacity 2s ease",
                  paddingLeft: "10px",
                  marginTop: "60px",
                  display: showDiv ? "block" : "none",
                  zIndex: 2,
                }}
              >
                {/* <Box className={classes.title}>
                  <Button
                    style={{
                      backgroundColor: baseColors.yellow,
                      borderRadius: 0,
                      width: "100%",
                      height: "40px",
                    }}
                    onClick={handleOpen}
                  >
                    <CustomText
                      color="white"
                      fontSize={"25px"}
                      fontWeight={900}
                      textTransform={"uppercase"}
                      fontFamily="Ricard-Black"
                      style={{ position: "absolute", zIndex: 1 }}
                    >
                      Title
                    </CustomText>
                    <CustomText
                      fontSize={"25px"}
                      fontWeight={900}
                      textTransform={"uppercase"}
                      fontFamily="RicardOmbre-Black"
                      style={{ position: "absolute", zIndex: 2 }}
                    >
                      Title
                    </CustomText>
                  </Button>
                </Box> */}
                <Tube />
                <Box sx={{ marginTop: "10px" }}>
                  <Texture
                    selected={currentIndex}
                    setImage={setImage}
                    setSelectSide={setSide}
                  />
                </Box>
                <Box display={"flex"}>
                  <Box>
                    <Liquide
                      property1="toolbox-liquide-1"
                      setLiquide={setCurrentLiquide}
                    />
                  </Box>
                  <Box sx={{ marginTop: "10px" }}>
                    <FondNew setCurrentVideo={setCurrentVideo} />
                  </Box>
                </Box>
                <Box>
                  <Button
                    style={{
                      backgroundColor: isHoveredOne
                        ? baseColors.blue.dark
                        : baseColors.yellow,
                      border: isHoveredOne ? "3px solid white" : "none",
                      borderRadius: 0,
                      width: "95%",
                      height: "40px",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                    onMouseEnter={handleMouseEnterOne}
                    onMouseLeave={handleMouseLeaveOne}
                    onClick={handlePlayPause}
                  >
                    <CustomText
                      color="white"
                      fontSize={"24px"}
                      fontWeight={900}
                      textTransform={"uppercase"}
                      fontFamily="Ricard-Black"
                      style={{ position: "absolute", zIndex: 1 }}
                    >
                      Verser
                    </CustomText>
                    <CustomText
                      fontSize={"24px"}
                      fontWeight={900}
                      textTransform={"uppercase"}
                      fontFamily="RicardOmbre-Black"
                      style={{ position: "absolute", zIndex: 2 }}
                    >
                      Verser
                    </CustomText>
                  </Button>
                  <Button
                    style={{
                      backgroundColor: isHovered
                        ? baseColors.blue.dark
                        : baseColors.yellow,
                      border: isHovered ? "3px solid white" : "none",
                      borderRadius: 0,
                      width: "95%",
                      height: "100px",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleList}
                  >
                    <CustomText
                      color="white"
                      fontSize={"30px"}
                      fontWeight={900}
                      textTransform={"uppercase"}
                      fontFamily="Ricard-Black"
                      lineHeight={"31px"}
                      style={{ position: "absolute", zIndex: 1 }}
                    >
                      PERSONNALISER MON OBJET
                    </CustomText>
                    <CustomText
                      fontSize={"30px"}
                      fontWeight={900}
                      textTransform={"uppercase"}
                      fontFamily="RicardOmbre-Black"
                      lineHeight={"31px"}
                      style={{ position: "absolute", zIndex: 1 }}
                    >
                      PERSONNALISER MON OBJET
                    </CustomText>
                  </Button>
                </Box>
              </div>
            </Slide>
          </Box>
        </Box>

        {isMobile ? (
          <Box className={classes.boxTwoMobile}>
            <Box className={imageBox} style={{ overflow: "hidden" }}>
            {iOS() ? <img
                    src={require(`../../assets/image/fonddefIM/${currentVideo}/0000.jpg`)}
                    alt={`Image 0`}
                    style={{
                      position: "relative",
                      height: "75vh",
                      width: "calc(75vh * 600 / 1024)",
                      zIndex: 0,
                    }}
                  />:<video
                key={currentVideo}
                autoPlay
                loop
                muted
                controls={false}
                style={{
                  position: "absolute",
                  height: "75vh",
                  width: "calc(75vh * 600 / 1024)",
                  zIndex: 1,
                }}
              >
                <source
                  src={require(`../../assets/image/fond/${currentVideo}.webm`)}
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>}
              {iOS() ? <img
                    src={require(`../../assets/image/liquidefIM/${currentIndex}/${currentLiquide}/0000.png`)}
                    alt={`Image 8`}
                    style={{
                      position: "absolute",
                      height: "75vh",
                      width: "calc(75vh * 600 / 1024)",
                      zIndex: 2,
                    }}
                  />:<video
                key={`${currentIndex}-${currentLiquide}`}
                ref={videoRef}
                loop={false}
                muted
                controls={false}
                style={{
                  position: "absolute",
                  height: "75vh",
                  width: "calc(75vh * 600 / 1024)",
                  zIndex: 2,
                }}
              >
                <source
                  src={require(`../../assets/image/anim/${currentIndex}/${currentLiquide}/${currentLiquide}.mov`)}
                />
                <source
                  src={require(`../../assets/image/anim/${currentIndex}/${currentLiquide}/${currentLiquide}.webm`)}
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>}
              <img
                src={require(`../../assets/image/tub/${currentIndex}/${image}.png`)}
                alt={`Image ${currentIndex}`}
                style={{
                  position: "absolute",
                  height: "75vh",
                  width: "calc(75vh * 600 / 1024)",
                  zIndex: 1,
                }}
              />
              <Box className={classes.toolBoxMobile}>
                <Box>
                  <button
                    style={{
                      width: "67px",
                      height: "55px",
                      background: baseColors.blue.dark,
                    }}
                    onClick={handleChange}
                  >
                    <IconPlusMobile />
                  </button>
                </Box>
                <Box
                  style={{
                    width: "calc(100vw - 67px)",
                    display: "flex",
                    height: "55px",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "right",
                    paddingRight: 10,
                    zIndex: "10px",
                  }}
                >
                  <CustomText
                    fontSize={"24px"}
                    fontWeight={900}
                    textTransform={"uppercase"}
                  >
                    User{userId}
                  </CustomText>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={isMobile ? "" : classes.boxTwo}>
            <Box
              className={imageBox}
              style={{ position: "relative", overflow: "hidden" }}
            >
              {iOS() ? <img
                    src={require(`../../assets/image/fonddefIM/${currentVideo}/0000.jpg`)}
                    alt={`Image 0`}
                    style={{
                      position: "relative",
                      height: isMobile ? "calc(100vh - 166px)" : "75vh",
                    width: isMobile ? "100%" : "calc(75vh * 600 / 1024)",
                      zIndex: 0,
                    }}
                  />:<video
                key={currentVideo}
                autoPlay
                loop
                muted
                controls={false}
                style={{
                  position: "absolute",
                  height: isMobile ? "calc(100vh - 166px)" : "75vh",
                  width: isMobile ? "100%" : "calc(75vh * 600 / 1024)",
                  zIndex: 1,
                }}
              >
                <source
                  src={require(`../../assets/image/fond/${currentVideo}.webm`)}
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>}
              {iOS() ? <img
                    src={require(`../../assets/image/liquidefIM/${currentIndex}/${currentLiquide}/0000.png`)}
                    alt={`Image 8`}
                    style={{
                      position: "absolute",
                      height: "75vh",
                      width: "calc(75vh * 600 / 1024)",
                      zIndex: 2,
                    }}
                  />:<video
                key={`${currentIndex}-${currentLiquide}`}
                ref={videoRef}
                loop={false}
                muted
                controls={false}
                style={{
                  position: "absolute",
                  height: isMobile ? "calc(100vh - 166px)" : "75vh",
                  width: isMobile ? "100%" : "calc(75vh * 600 / 1024)",
                  zIndex: 2,
                }}
              >
                <source
                  src={require(`../../assets/image/anim/${currentIndex}/${currentLiquide}/${currentLiquide}.mov`)}
                />
                <source
                  src={require(`../../assets/image/anim/${currentIndex}/${currentLiquide}/${currentLiquide}.webm`)}
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>}
              {/* <video
                key={currentVideo}
                autoPlay
                loop
                muted
                controls={false}
                style={{
                  position: "absolute",
                  height: isMobile ? "calc(100vh - 166px)" : "75vh",
                  width: isMobile ? "100%" : "calc(75vh * 600 / 1024)",
                  zIndex: 1,
                }}
              >
                <source
                  src={require(`../../assets/image/fond/${currentVideo}.webm`)}
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
              <video
                key={`${currentIndex}-${currentLiquide}`}
                ref={videoRef}
                loop={false}
                muted
                controls={false}
                style={{
                  position: "absolute",
                  height: isMobile ? "calc(100vh - 166px)" : "75vh",
                  width: isMobile ? "100%" : "calc(75vh * 600 / 1024)",
                  zIndex: 2,
                }}
              >
                <source
                  src={require(`../../assets/image/anim/${currentIndex}/${currentLiquide}/${currentLiquide}.mov`)}
                />
                <source
                  src={require(`../../assets/image/anim/${currentIndex}/${currentLiquide}/${currentLiquide}.webm`)}
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video> */}
              <img
                src={require(`../../assets/image/tub/${currentIndex}/${image}.png`)}
                alt={`Image ${currentIndex}`}
                style={{
                  position: "absolute",
                  height: isMobile ? "calc(100vh - 166px)" : "75vh",
                  width: isMobile ? "100%" : "calc(75vh * 600 / 1024)",
                  zIndex: 1,
                }}
              />
            </Box>
          </Box>
        )}
        {isMobile && (
          <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
            <Box
              sx={{
                bottom: "86px",
                width: "100%",
                zIndex: 3,
                position: "absolute",
                marging: "auto",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  zIndex: 3,
                  marging: "auto",
                  diplay: "flex",
                  justifyContent: "center",
                }}
              >
                {currentBox === 0 ? <Tube /> : null}
                {currentBox === 1 ? (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                  >
                    <FondNew setCurrentVideo={setCurrentVideo} />
                    <Liquide
                      property1="toolbox-liquide-1"
                      setLiquide={setCurrentLiquide}
                    />
                  </Box>
                ) : null}
                {currentBox === 2 ? (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                  >
                    <Texture selected={currentIndex} setImage={setImage} />
                  </Box>
                ) : null}
                {currentBox === 3 ? (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                  >
                    <Button
                      style={{
                        backgroundColor: baseColors.yellow,
                        border: "3px solid white",
                        borderRadius: 0,
                        width: "calc(100% - 35px)",
                        height: "280px",
                        margin: "auto",
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleList}
                    >
                      <CustomText
                        color="white"
                        fontSize={"32px"}
                        fontWeight={900}
                        textTransform={"uppercase"}
                        lineHeight={"50px"}
                        fontFamily="Ricard-Black"
                        style={{ position: "absolute", zIndex: 1 }}
                      >
                        PERSONNALISER MON OBJET
                      </CustomText>
                      <CustomText
                        fontSize={"32px"}
                        fontWeight={900}
                        textTransform={"uppercase"}
                        fontFamily="RicardOmbre-Black"
                        lineHeight={"50px"}
                        style={{ position: "absolute", zIndex: 2 }}
                      >
                        PERSONNALISER MON OBJET
                      </CustomText>
                    </Button>
                  </Box>
                ) : null}
              </Box>
              <Box
                sx={{
                  width: "calc(100% - 35px)",
                  height: "45px",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <button
                  style={{
                    width: "67px",
                    height: "45px",
                    background: baseColors.yellow,
                    border: "none",
                    borderRadius: 0,
                    marginRight: "10px",
                    justifyContent: "center",
                  }}
                  onClick={() => setCurrentBox((currentBox + 3) % 4)}
                >
                  <IconArrowLeftMob />
                </button>
                <Button
                  style={{
                    backgroundColor: isHoveredOne
                      ? baseColors.blue.dark
                      : baseColors.yellow,
                    border: isHoveredOne ? "3px solid white" : "none",
                    borderRadius: 0,
                    width: "calc(100% - 10px)",
                    height: "45px",
                  }}
                  onClick={handlePlayPause}
                >
                  <CustomText
                    fontSize={"24px"}
                    fontWeight={900}
                    textTransform={"uppercase"}
                  >
                    Verser
                  </CustomText>
                </Button>
                <button
                  style={{
                    width: "67px",
                    height: "45px",
                    background: baseColors.yellow,
                    border: "none",
                    borderRadius: 0,
                    marginLeft: "10px",
                  }}
                  onClick={() => setCurrentBox((currentBox + 1) % 4)}
                >
                  <IconArrowRightMob />
                </button>
              </Box>
            </Box>
          </Slide>
        )}
      </Box>
    </Box>
  );
};
