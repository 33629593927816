import { makeStyles } from "@mui/styles";
import { baseColors } from "../../Constants/baseStyles";


const personalizedStyles = makeStyles(() => ({
  contentBox: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 111px)",
    justifyContent: "center",
    width: "1280px",
    paddingLeft: "20px",
  },

  contentBoxMobile: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 111px)",
    justifyContent: "center",

  },
  boxOne: {

  },
  boxOneMobile: {
    display: 'none'
  },
  boxTwo: {
    width: "103%",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  boxTwoMobile: {
    display: "flex",
    justifyContent: "center",
  },
  boxThree: {
    justifyContent: 'right',
    textAlign: 'right',
    paddingLeft: 0,
    flexDirection: "column",
  },
  boxThreeMobile: {
    display: 'none'
  },
  boxImage: {
    height: "75vh",
    width: "calc(75vh * 600 / 1024)",
    border: "10px solid #FFF",
    display:"flex",
    justifyContent: "center",
    alignItems: "center"
  },
  boxImageMobile: {
    height: "calc(100vh - 166px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
  },
  toolBoxMobile: {
    bottom: '31px',
    position: 'fixed',
    backgroundColor: baseColors.yellow,
    width: "100vw",
    height: "55px",
    flexDirection: 'row',
    display: 'flex',
    zIndex: 999,
  },
  toolContent: {
    
  },
  title: {
    backgroundColor: baseColors.yellow,
    heigth: "45px",
    display: "flex",
    justifyContent: "center",
    width: "314px",
  },
  tube: {
    border: "8px solid",
    borderColor: baseColors.yellow,
    backgroundColor: "white",
    width: "298px",
    height: "auto",
    marginTop: "10px",
    justifyContent: "center",
  },
  tubeMobile: {
    border: "8px solid",
    borderColor: baseColors.yellow,
    backgroundColor: "white",
    width: "calc(100% - 52px)",
    height: "auto",
    margin: "auto",
    justifyContent: "center",
  },
  selectTube: {
    display: "flex",
    justifyContent: "center",
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginTop: "10px",
  },
  selectTubeBottom: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "center",
    gap: '10px'
  },
  boxVitess: {
    display: "flex",
    border: "8px solid",
    borderColor: baseColors.yellow,
    backgroundColor: "white",
    width: "19.55vw",
    height: "50px",
    marginTop: "10px",
    justifyContent: "center",
    alignItems: 'center',
  },
  
}));

export default personalizedStyles;
