import { makeStyles } from "@mui/styles";
import { baseColors } from "../../Constants/baseStyles";
import back from "../../assets/image/Fond_page_connexion.jpg";

const loginStyles = makeStyles(() => ({
  login: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    height: "calc(100vh - 111px)",
    alignItems: 'center',
    backgroundImage: `url(${back})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
  },
  boxContent: {
    backgroundColor: "white",
    padding: '20px',
    display: "flex",
    justifyContent: "center",
    maxHeight: "calc(100vh - 160px)",
  },
  boxContentHead: {
    textAlign: 'center',
    height: 'auto',
  }, 
  boxButtonOne: {
    marginTop: '20px',
    flexDirection:"column",
  },
  boxButtonTwo: {
    marginTop: '25px',
  },
  titleButton: {
    textAlign: 'center',
  },
  retour:{
    marginTop: '15px',
  },
  btnOne: {
    width: "325px",
    height: '45px',
    backgroundColor: "white",
    borderRadius: 0,
    border: 'none',
    transition: "background-color 0.3s ease",
    "&:hover": {
        backgroundColor: baseColors.blue.dark,
        color: "white", 
      },
  },
}));

export default loginStyles;
