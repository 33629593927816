import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import listStyles from "./ListStyles";
import { CustomText } from "../../Components/CustomeText/CustomText";
import { baseColors } from "../../Constants/baseStyles";
import back from "../../assets/image/Fond_page_connexion.jpg";

export const List = () => {
  const classes = listStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const pricipalBox = isMobile ? classes.principalMobile : classes.principal;
  const [userNFT, setUserNFT] = React.useState<any>(null);
  // const [userNFT, setUserNFT] = React.useState<any>({IDU:123,BG:1,LIQ:2,COL:3,TUB:4,link:"https://www.opensea.com/nft"});
  const [allNFTs, setAllNFTs] = React.useState<any>([]);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/api/getList.php").then(res => res.json()).then(response => {
      console.log(response)
      if(response.USER && response.USER.IDU){
        setUserNFT(response.USER)
      }
      setAllNFTs(response.LIST)
    })

    // setAllNFTs([
    //   {
    //       "IDU": 2710,
    //       "BG": 5,
    //       "LIQ": 1,
    //       "COL": 3,
    //       "TUB": 2
    //   },
    //   {
    //       "IDU": 2190,
    //       "BG": 0,
    //       "LIQ": 2,
    //       "COL": 4,
    //       "TUB": 1
    //   },
    //   {
    //       "IDU": 653,
    //       "BG": 3,
    //       "LIQ": 1,
    //       "COL": 0,
    //       "TUB": 0
    //   },
    //   {
    //       "IDU": 1394,
    //       "BG": 1,
    //       "LIQ": 3,
    //       "COL": 4,
    //       "TUB": 0
    //   },
    //   {
    //       "IDU": 7229,
    //       "BG": 0,
    //       "LIQ": 4,
    //       "COL": 5,
    //       "TUB": 2
    //   },
    //   {
    //       "IDU": 1025,
    //       "BG": 2,
    //       "LIQ": 3,
    //       "COL": 5,
    //       "TUB": 3
    //   },
    //   {
    //       "IDU": 6824,
    //       "BG": 1,
    //       "LIQ": 4,
    //       "COL": 5,
    //       "TUB": 5
    //   },
    //   {
    //       "IDU": 2633,
    //       "BG": 1,
    //       "LIQ": 0,
    //       "COL": 2,
    //       "TUB": 0
    //   },
    //   {
    //       "IDU": 3059,
    //       "BG": 1,
    //       "LIQ": 1,
    //       "COL": 4,
    //       "TUB": 0
    //   },
    //   {
    //       "IDU": 5651,
    //       "BG": 2,
    //       "LIQ": 5,
    //       "COL": 1,
    //       "TUB": 0
    //   },
    //   {
    //       "IDU": 7159,
    //       "BG": 4,
    //       "LIQ": 0,
    //       "COL": 0,
    //       "TUB": 4
    //   },
    //   {
    //       "IDU": 9003,
    //       "BG": 3,
    //       "LIQ": 1,
    //       "COL": 5,
    //       "TUB": 5
    //   },
    //   {
    //       "IDU": 2475,
    //       "BG": 3,
    //       "LIQ": 3,
    //       "COL": 4,
    //       "TUB": 0
    //   },
    //   {
    //       "IDU": 1679,
    //       "BG": 1,
    //       "LIQ": 5,
    //       "COL": 4,
    //       "TUB": 1
    //   },
    //   {
    //       "IDU": 4977,
    //       "BG": 3,
    //       "LIQ": 5,
    //       "COL": 5,
    //       "TUB": 3
    //   },
    //   {
    //       "IDU": 4548,
    //       "BG": 2,
    //       "LIQ": 0,
    //       "COL": 0,
    //       "TUB": 3
    //   },
    //   {
    //       "IDU": 9643,
    //       "BG": 5,
    //       "LIQ": 0,
    //       "COL": 4,
    //       "TUB": 5
    //   },
    //   {
    //       "IDU": 6103,
    //       "BG": 1,
    //       "LIQ": 1,
    //       "COL": 3,
    //       "TUB": 5
    //   },
    //   {
    //       "IDU": 3117,
    //       "BG": 1,
    //       "LIQ": 4,
    //       "COL": 0,
    //       "TUB": 1
    //   },
    //   {
    //       "IDU": 6005,
    //       "BG": 2,
    //       "LIQ": 3,
    //       "COL": 5,
    //       "TUB": 3
    //   },
    //   {
    //       "IDU": 8388,
    //       "BG": 4,
    //       "LIQ": 5,
    //       "COL": 0,
    //       "TUB": 3
    //   },
    //   {
    //       "IDU": 9801,
    //       "BG": 3,
    //       "LIQ": 1,
    //       "COL": 1,
    //       "TUB": 3
    //   },
    //   {
    //       "IDU": 1657,
    //       "BG": 3,
    //       "LIQ": 1,
    //       "COL": 0,
    //       "TUB": 0
    //   },
    //   {
    //       "IDU": 2660,
    //       "BG": 3,
    //       "LIQ": 5,
    //       "COL": 1,
    //       "TUB": 4
    //   },
    //   {
    //       "IDU": 8913,
    //       "BG": 0,
    //       "LIQ": 3,
    //       "COL": 0,
    //       "TUB": 4
    //   },
    //   {
    //       "IDU": 7478,
    //       "BG": 5,
    //       "LIQ": 0,
    //       "COL": 4,
    //       "TUB": 0
    //   },
    //   {
    //       "IDU": 3683,
    //       "BG": 4,
    //       "LIQ": 0,
    //       "COL": 5,
    //       "TUB": 2
    //   },
    //   {
    //       "IDU": 1363,
    //       "BG": 1,
    //       "LIQ": 1,
    //       "COL": 2,
    //       "TUB": 0
    //   },
    //   {
    //       "IDU": 7956,
    //       "BG": 2,
    //       "LIQ": 3,
    //       "COL": 2,
    //       "TUB": 3
    //   },
    //   {
    //       "IDU": 9414,
    //       "BG": 3,
    //       "LIQ": 4,
    //       "COL": 3,
    //       "TUB": 1
    //   },
    //   {
    //       "IDU": 2443,
    //       "BG": 0,
    //       "LIQ": 5,
    //       "COL": 3,
    //       "TUB": 1
    //   },
    //   {
    //       "IDU": 7830,
    //       "BG": 3,
    //       "LIQ": 0,
    //       "COL": 2,
    //       "TUB": 4
    //   },
    //   {
    //       "IDU": 8005,
    //       "BG": 3,
    //       "LIQ": 0,
    //       "COL": 2,
    //       "TUB": 5
    //   },
    //   {
    //       "IDU": 8689,
    //       "BG": 3,
    //       "LIQ": 5,
    //       "COL": 1,
    //       "TUB": 5
    //   },
    //   {
    //       "IDU": 8239,
    //       "BG": 5,
    //       "LIQ": 4,
    //       "COL": 3,
    //       "TUB": 0
    //   },
    //   {
    //       "IDU": 7103,
    //       "BG": 1,
    //       "LIQ": 0,
    //       "COL": 3,
    //       "TUB": 2
    //   },
    //   {
    //       "IDU": 3186,
    //       "BG": 0,
    //       "LIQ": 0,
    //       "COL": 4,
    //       "TUB": 4
    //   },
    //   {
    //       "IDU": 6431,
    //       "BG": 5,
    //       "LIQ": 3,
    //       "COL": 4,
    //       "TUB": 2
    //   },
    //   {
    //       "IDU": 67,
    //       "BG": 3,
    //       "LIQ": 1,
    //       "COL": 2,
    //       "TUB": 4
    //   },
    //   {
    //       "IDU": 7440,
    //       "BG": 1,
    //       "LIQ": 1,
    //       "COL": 0,
    //       "TUB": 4
    //   }
    // ])
  },[])

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  return (
    <Box>
      {userNFT && <Box sx={{width: "100%", backgroundImage: `url(${back})`, backgroundSize: 'cover', backgroundPosition:'center'}} >
        <Box className={classes.boxOne}>
          <Box className={pricipalBox}>
            {!isMobile ? (
              <Box className={classes.title}>
                <Box className={classes.titleText}>
                  <CustomText
                    fontSize={"36px"}
                    fontWeight={900}
                    fontFamily="Ricard-Black"
                  >
                    RICARD 3.0 #{userNFT.IDU}
                  </CustomText>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{ width: "100%", textAlign: "center", paddingTop: "10px" }}
              >
                <CustomText
                  fontSize={"24px"}
                  fontWeight={900}
                  lineHeight={"40px"}
                  fontFamily="Ricard-Black"
                >
                  RICARD 3.0 #{userNFT.IDU}
                </CustomText>
              </Box>
            )}
            <Box className={classes.content}>
              <Box flexDirection={"column"}>
                <Box
                  className={classes.boxImage}
                  style={{ position: "relative", overflow: "hidden", cursor: userNFT.link ? "pointer" : "auto"}}
                  onClick={() => userNFT.link && window.open(userNFT.link, "_blank")}
                >
                  {iOS() ? <img
                    src={require(`../../assets/image/fonddefIM/${userNFT.BG}/0000.jpg`)}
                    alt={`Image 0`}
                    style={{
                      position: "absolute",
                      left: 0,
                      height: "75vh",
                      width: "calc(75vh * 600 / 1024)",
                      zIndex: 0,
                    }}
                  />:<video
                    // key={currentVideo}
                    autoPlay
                    loop
                    muted
                    controls={false}
                    style={{
                      position: "absolute",
                      left: 0,
                      height: "75vh",
                      width: "calc(75vh * 600 / 1024)",
                      zIndex: 1,
                    }}
                  >
                    <source
                      src={require(`../../assets/image/fond/${userNFT.BG}.webm`)}
                      type="video/webm"
                    />
                    Your browser does not support the video tag.
                  </video>}
                  <img
                    src={require(`../../assets/image/tub/${userNFT.LIQ}/${userNFT.TUB}.png`)}
                    alt={`Image ${userNFT.LIQ}`}
                    style={{
                      position: "absolute",
                      left: 0,
                      height: "75vh",
                      width: "calc(75vh * 600 / 1024)",
                      zIndex: 1,
                    }}
                  />
                  {iOS() ? <img
                    src={require(`../../assets/image/liquidefIM/${userNFT.LIQ}/${userNFT.COL}/0000.png`)}
                    alt={`Image 8`}
                    style={{
                      position: "absolute",
                      left: 0,
                      height: "75vh",
                      width: "calc(75vh * 600 / 1024)",
                      zIndex: 2,
                    }}
                  />:<video
                    // key={`${currentIndex}-${currentLiquide}`}
                    // ref={videoRef}
                    autoPlay
                    loop={true}
                    muted
                    controls={false}
                    style={{
                      position: "absolute",
                      left: 0,
                      height: "75vh",
                      width: "calc(75vh * 600 / 1024)",
                      zIndex: 2,
                    }}
                  >
                    <source
                      src={require(`../../assets/image/anim/${userNFT.LIQ}/${userNFT.COL}/${userNFT.COL}.mov`)}
                    />
                    <source
                      src={require(`../../assets/image/anim/${userNFT.LIQ}/${userNFT.COL}/${userNFT.COL}.webm`)}
                      type="video/webm"
                    />
                    Your browser does not support the video tag.
                  </video>}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>}
      <Box className={classes.boxOne}>
        <Box className={classes.boxContentGalery}>
          <Box className={classes.titleTwo}>
            <CustomText
              fontSize={"64px"}
              fontWeight={900}
              textTransform={"uppercase"}
              fontFamily="Ricard-Black"
            >
              tous les nft
            </CustomText>
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Box
              className={isMobile ? classes.boxGaleryMobile : classes.boxGalery}
            >
              {allNFTs.map((nft:any, index:Number) => (
                <Box
                  className={
                    isMobile
                      ? classes.boxImageGaleryMobile
                      : classes.boxImageGalery
                  }
                  style={{ position: "relative", overflow: "hidden",  cursor: nft.link ? "pointer" : "auto"}}
                  onClick={() => nft.link && window.open(nft.link, "_blank")}
                >
                  <img
                    src={require(`../../assets/image/fonddefIM/${nft.BG}/0000.jpg`)}
                    alt={`Image 0`}
                    style={{
                      position: "relative",
                      height: isMobile ? "30vh" : "",
                      width: "100%",
                      zIndex: 0,
                      objectFit: "cover",
                    }}
                  />
                  <img
                    src={require(`../../assets/image/tub/${nft.LIQ}/${nft.TUB}.png`)}
                    alt={`Image 8`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: isMobile ? "30vh" : "",
                      width: "100%",
                      zIndex: 1,
                      objectFit: "cover",
                    }}
                  />
                  <img
                    src={require(`../../assets/image/liquidefIM/${nft.LIQ}/${nft.COL}/0000.png`)}
                    alt={`Image 8`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: isMobile ? "30vh" : "",
                      width: "100%",
                      zIndex: 2,
                      objectFit: "cover",
                    }}
                  />
                  {/* <video
                    // key={currentVideo}
                    autoPlay
                    loop
                    muted
                    controls={false}
                    style={{
                      position: "relative",
                      height: isMobile ? "30vh" : "",
                      width: "100%",
                      zIndex: 1,
                      objectFit: "cover",
                    }}
                  >
                    <source
                      src={require(`../../assets/image/fonddefIM/${nft.BG}/OOOO.jpg`)}
                      type="video/webm"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <img
                    src={require(`../../assets/image/tub/${nft.LIQ}/${nft.TUB}.png`)}
                    alt={`Image ${nft.LIQ}`}
                    style={{
                      position: "absolute",
                      height: isMobile ? "30vh" : "",
                      width: "100%",
                      top: 0,
                      left: 0,
                      zIndex: 1,
                      objectFit: "cover",
                    }}
                  />
                  <video
                    // key={`${currentIndex}-${currentLiquide}`}
                    // ref={videoRef}
                    autoPlay
                    loop={true}
                    muted
                    controls={false}
                    style={{
                      position: "absolute",
                      height: isMobile ? "30vh" : "",
                      width: "100%",
                      top: 0,
                      left: 0,
                      zIndex: 2,
                      objectFit: "cover",
                    }}
                  >
                    <source
                      src={require(`../../assets/image/liquidefIM/${nft.LIQ}/${nft.COL}/0000.png`)}
                      type="video/webm"
                    />
                    Your browser does not support the video tag.
                  </video> */}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
