import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import loginStyles from "./LoginStyles";
import { CustomText } from "../../Components/CustomeText/CustomText";
import { Link } from "react-router-dom";
import { baseColors } from "../../Constants/baseStyles";
import { useNavigate } from "react-router";

export const Login = () => {
  const classes = loginStyles();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleLog = () => {
    navigate("/personalised");
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Grid className={classes.login}>
      <Box
        className={classes.boxContent}
        sx={{ width: isMobile ? "80%" : "333px", overflow:"hidden" }}
      >
        <Box className={classes.boxContentHead}>
          <CustomText
            fontSize={"24px"}
            fontWeight={1000}
            textTransform={"uppercase"}
            fontFamily="Ricard-Black"
          >
            UN GUIDE AU SERVICE À PERSONNALISER
          </CustomText>
          <Box sx={{ margin: "20px" }}>
            <CustomText fontSize={"14px"} fontWeight={400}>
              Pour cette collaboration avec Ricard, l'artiste contemporain Neïl
              BELOUFA imagine 6 objets de service proposant un mode de
              préparation interactif grâce aux technologies Web3. De véritables
              guides au service qui peuvent être personnalisés en connectant un
              portefeuille, et suivant les différentes étapes ! La participation
              à la mécanique de personnalisation déclenche l'obtention d'un NFT
              RICARD 3.0, donnant droit à une dégustation lors du Salon NFT
              PARIS.
            </CustomText>
          </Box>
          <Box className={classes.boxButtonOne}>
            <Box className={classes.titleButton}>
              <CustomText
                fontSize={"13px"}
                fontWeight={300}
                color={baseColors.grey}
              >
                Pour participer & recevoir mon NFT
              </CustomText>
            </Box>

            <Button
              style={{
                width: "100%",
                height: "76px",
                backgroundColor: isHovered
                  ? baseColors.blue.dark
                  : baseColors.yellow,
                borderRadius: 0,
              }}
              onClick={() => handleLog()}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <CustomText
                color={!isHovered
                  ? baseColors.blue.dark
                  : "white"}
                fontSize={"25px"}
                fontWeight={900}
                lineHeight={"30px"}
                textTransform={"uppercase"}
                fontFamily="Ricard-Black"
                style={{ position: "absolute", zIndex: 1 }}
              >
                Connecter mon Portefeuille
              </CustomText>
            </Button>
            <Box marginTop={"10px"}>
              <Link
                to={process.env.PUBLIC_URL + "/phpage/CGU.pdf"}
                style={{ textDecoration: "none" }}
              >
                <CustomText fontSize={"13px"} fontWeight={0}>
                  Mentions légales & CGU
                </CustomText>
              </Link>
            </Box>
          </Box>
          <Box className={classes.retour}>
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <CustomText
                fontSize={"13px"}
                fontWeight={900}
                textTransform={"uppercase"}
                fontFamily="Ricard-Black"
              >
                Retour
              </CustomText>
            </Link>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
