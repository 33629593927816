import React from "react";

export const iconPlusMobile: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
    >
      <path
        d="M25.2909 8.64995V18.1464H17.7865V26.1297H8.43166V18.1464H0.927246V8.64995H8.43166V0.666687H17.7865V8.64995H25.2909Z"
        fill="white"
      />
    </svg>
  );
};

export default iconPlusMobile;
