import React, { useContext, useEffect, useRef, useState } from "react";
import menuStyles from "./MenuStyles";
import { Box } from "@mui/material";
import { CustomText } from "../CustomeText/CustomText";
import { TextContext } from "../../Provider/TextColorProviders";
import { Link } from "react-scroll";

const items = [ //menu horizontal desktop
  "RICARD 3.0",
  "UN PROJET HERITAGE",
  "UNE COLLABORATION INEDITE",
  "6 OBJETS UNIQUES",
  "UNE MECANIQUE INTERACTIVE",
  "UN PROJET RICHE DE SENS",
  "FAQ",
];

export const MenuNew = () => {
  const classes = menuStyles();
  const [activeMenuIndex, setActiveMenuIndex] = useState(-1);
  const { textColor, activeMenu } = useContext(TextContext);
  const boxMenuRef = useRef<HTMLDivElement>(null);

  const handleMenuClick = (index: any) => {
    setActiveMenuIndex(index);
  };

  useEffect(() => {
    setActiveMenuIndex(parseInt(activeMenu) - 1);
  }, [activeMenu]);

  return (
    <div className={"menuContent"}>
      <div className={classes.menuLateral} id={"menuBoxx"} ref={boxMenuRef}>
        {items.map((item, index) => (
          <Link
            to={(index + 1).toString()}
            smooth={true}
            duration={500}
            offset={-120}
          >
            <Box key={index} sx={{ cursor: "pointer"}} onClick={() => handleMenuClick(index)}>
              <CustomText
                color="white"
                fontSize={"14px"}
                fontWeight={400}
                fontFamily={
                  activeMenuIndex === index ? "Ricard-Gras" : "Ricard-Normal"
                }
              >
                {item}
              </CustomText>
            </Box>
          </Link>
        ))}
      </div>
    </div>
  );
};
