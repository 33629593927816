import {
  Box,
  Button,
  Checkbox,
  styled,
  Drawer,
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
  Slide,
} from "@mui/material";
import { FC, useEffect, useState, useRef, useContext } from "react";
import { AppBarHeader } from "../AppBarHeader/AppBarHeader";
import { MenuBar } from "../Menu/MenuBar";
import wrapperStyles from "./WrapperStyles";
import { CustomText } from "../CustomeText/CustomText";
import { Link, useLocation } from "react-router-dom";
import { baseColors } from "../../Constants/baseStyles";
import { CustomTextFields } from "../Input/CustomTextField";
import CustomCheckbox from "../CustomCheckBox/CustomCheckBox";
import { TextContext } from "../../Provider/TextColorProviders";
import { MenuNew } from "../Menu/MenuNew";
import MenuMobile from "../../assets/icone/MenuMobile";
import { useNavigate } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

type IProps = {
  children: any;
};

export const WrapperComponent: FC<IProps> = (props) => {
  const { children } = props;
  const classes = wrapperStyles();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isMenu = useMediaQuery("(max-width:1278px)");
  const storedAge = sessionStorage.getItem("age");
  const storedOpenDrawer = sessionStorage.getItem("openDrawer");
  const navigate = useNavigate();
  const decouvrir = () => {
    //navigate("/login");
    window.location.href = process.env.PUBLIC_URL + "/phpage/connect.php"
  };

  const initialAge = storedAge ? parseInt(storedAge) : 0;
  const initialOpenDrawer = storedOpenDrawer
    ? JSON.parse(storedOpenDrawer)
    : true;
  const [openDrawer, setOpenDrawer] = useState(initialOpenDrawer);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [age, setAge] = useState(initialAge);
  const [errorAge, setErrorAge] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [activeSection, setActiveSection] = useState<string | null>("-1");
  const { setActiveMenu, setTextColor } = useContext(TextContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const observer = useRef<IntersectionObserver | null>(null);
  const refBlueBox1 = useRef<HTMLDivElement>(null);
  const boxMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeSection !== null) {
      setActiveMenu(activeSection);
    }
  }, [activeSection]);

  const handleClick = (section: any) => {
    window.location.href = `/#${section}`;
  };

  const itemsHamburger = [ // menu hamburger mobile
    "RICARD 3.0",
    "UN PROJET HERITAGE",
    "UNE COLLABORATION INEDITE",
    "6 OBJETS UNIQUES",
    "UNE MECANIQUE INTERACTIVE",
    "UN PROJET RICHE DE SENS",
    "FAQ",
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (observer !== null) {
        observer.current = new IntersectionObserver(
          (entries) => {
            const menuBox = document.querySelector(".menuContent");

            const menuEntries = entries.filter(
              (entry) => entry.target === menuBox && entry.isIntersecting
            );
            const isMenuBoxFullyInViewport = entries.some(
              (entry) =>
                entry.target === menuBox &&
                entry.isIntersecting &&
                entry.intersectionRatio === 1.0
            );

            const sections = Array.from(
              document.querySelectorAll("[data-section]")
            );

            sections.forEach((section) => {
              const rect = section.getBoundingClientRect();
              const rectMenu = menuBox?.getBoundingClientRect();

              // Vérifie si l'élément menuBox est complètement contenu dans la section
              if (
                rectMenu &&
                rect.left <= rectMenu.left &&
                rect.right >= rectMenu.right &&
                rect.top <= rectMenu.top &&
                rect.bottom >= rectMenu.bottom
              ) {
                const sectionId = section.getAttribute("id");
                setActiveSection(sectionId);
              }
            });
          },
          { threshold: 1.0 }
        );

        const sections = document.querySelectorAll("[data-section]");
        sections.forEach((section) => {
          observer.current?.observe(section);
        });

        return () => {
          sections.forEach((section) => {
            observer.current?.unobserve(section);
          });
        };
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = Array.from(document.querySelectorAll("[data-section]"));

      sections.forEach((section) => {
        const rectSection = section.getBoundingClientRect();
        const sectionId = section.getAttribute("id");

        if (sectionId) {
          const index = parseInt(sectionId.split("-")[0], 6);
          const textMenu = document.getElementById(`menu-${index}`);
          const rectTextMenu = textMenu?.getBoundingClientRect();

          if (
            rectTextMenu &&
            rectSection.left <= rectTextMenu.left &&
            rectSection.right >= rectTextMenu.right &&
            rectSection.top <= rectTextMenu.top &&
            rectSection.bottom >= rectTextMenu.bottom
          ) {
            setTextColor(sectionId);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setTextColor]);

  useEffect(() => {
    const handleScroll2 = () => {
      const BlueDivOne = document.getElementById("3");
      const BlueDivTwo = document.getElementById("7");

      if (BlueDivOne && BlueDivTwo) {
        const rectYellowDiv = BlueDivOne.getBoundingClientRect();

        const rectImageDiv = BlueDivTwo.getBoundingClientRect();

        const paragraph = document.getElementById("menu-1");
        if (paragraph) {
          const rectParagraph = paragraph.getBoundingClientRect();

          const dx = rectParagraph.x - rectYellowDiv.x;
          const dy = rectParagraph.y - rectYellowDiv.y;

          const distance = Math.sqrt(dx * dx + dy * dy);

          console.log("chris");
          console.log(distance);

          // if (
          //   rectParagraph.bottom <= rectYellowDiv.bottom + window.scrollY &&
          //   rectParagraph.top >= rectYellowDiv.top + window.scrollY
          // ) {

          // }
        }
      }
    };

    window.addEventListener("scroll", handleScroll2);

    return () => {
      window.removeEventListener("scroll", handleScroll2);
    };
  }, []);

  useEffect(() => {
    const container = document.getElementById("menuBoxx");
    const blueDiv = document.getElementById("3");
    const blueDivTwo = document.getElementById("7");

    if (!container || !blueDiv || !blueDivTwo) return;

    const handleScroll = () => {
      const rectContainer = container.getBoundingClientRect();
      const rectYellowDiv = blueDiv.getBoundingClientRect();
      const rectBlueDivTwo = blueDivTwo.getBoundingClientRect();

      for (let i = 0; i <= 7; i++) {
        const paragraph = document.getElementById(`menu${i}`);

        if (!paragraph) continue;

        const rectParagraph = paragraph.getBoundingClientRect();

        if (
          (rectParagraph.bottom <= rectYellowDiv.bottom &&
            rectParagraph.top >= rectYellowDiv.top) ||
          (rectParagraph.bottom <= rectBlueDivTwo.bottom &&
            rectParagraph.top >= rectBlueDivTwo.top)
        ) {
          paragraph.style.color = "white";
        } else {
          paragraph.style.color = baseColors.blue.dark;
        }
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   const container = document.getElementById("menuBoxx");
  //   const blueDiv = document.getElementById("7");

  //   if (!container || !blueDiv) return;

  //   const handleScroll = () => {
  //     const rectContainer = container.getBoundingClientRect();
  //     const rectYellowDiv = blueDiv.getBoundingClientRect();

  //     for (let i = 0; i <= 7; i++) {
  //       const paragraph = document.getElementById(`menu${i}`);

  //       if (!paragraph) continue;

  //       const rectParagraph = paragraph.getBoundingClientRect();

  //       if (
  //         rectParagraph.bottom <= rectYellowDiv.bottom &&
  //         rectParagraph.top >= rectYellowDiv.top
  //       ) {
  //         paragraph.style.color = 'white';
  //       } else {
  //         paragraph.style.color = baseColors.blue.dark;
  //       }
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const boxStyle = {
    backgroundColor: isChecked ? baseColors.blue.dark : "white",
    padding: "10px",
    marginLeft: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  };

  const calculateYearOfBirth = (age: number) => {
    const currentYear = new Date().getFullYear();
    return currentYear - age;
  };

  const checkScroll = () => {
    setScrolled(window.pageYOffset > 500);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  useEffect(() => {
    const yearOfBirth = calculateYearOfBirth(parseInt(dateOfBirth));
    setAge(yearOfBirth);
  }, [dateOfBirth]);

  const updateAgeAndOpenDrawer = (newAge: any, newOpenDrawer: any) => {
    sessionStorage.setItem("age", newAge);
    sessionStorage.setItem("openDrawer", JSON.stringify(newOpenDrawer));
    setAge(newAge);
    setOpenDrawer(newOpenDrawer);
  };
  const scrollToBottom = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleAgeValidation = () => {
    if (age >= 18) {
      setOpenDrawer(false);
      updateAgeAndOpenDrawer(age, false);
    } else {
      setErrorAge(true);
    }
  };

  const [isSticky, setSticky] = useState(false);
  const headerHeight = document.getElementById("imageOne")?.clientHeight || 0;

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > headerHeight) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Nettoyage du listener lors du démontage du composant
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let button;
  if (location.pathname === "/list") {
    if (!scrolled) {
      button = (
        <Box className={isMobile ? classes.buttonOneMobile : classes.buttonOne}>
          <button
            className={isMobile ? classes.btnOneMobile : classes.btnOne}
            onClick={scrollToBottom}
          >
            <CustomText
              color={"white"}
              fontSize={"24px"}
              fontWeight={900}
              textTransform={"uppercase"}
              fontFamily="Ricard-Black"
            >
              Tous les nft
            </CustomText>
          </button>
        </Box>
      );
    } else {
      button = (
        <Box className={isMobile ? classes.buttonOneMobile : classes.buttonOne}>
          <button
            className={isMobile ? classes.btnOneMobile : classes.btnOne}
            style={{ background: baseColors.yellow }}
            onClick={scrollToTop}
          >
            <CustomText
              fontSize={"24px"}
              fontWeight={900}
              textTransform={"uppercase"}
              fontFamily="Ricard-Black"
            >
              mon nft
            </CustomText>
          </button>
        </Box>
      );
    }
  }

  return (
    <div>
      <Box className={classes.header}>
        <AppBarHeader />
        {location.pathname === "/" && !isMobile && !isMenu && <MenuNew />}
        {location.pathname === "/" && isMobile && (
          <Box
            sx={{
              backgroundColor: baseColors.blue.dark,
              height: "60px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ marginLeft: "20px" }}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <MenuMobile color="white"/>
            </Box>
            <Box sx={{ marginRight: "20px" }}>
              {/* <Button
                style={{
                  backgroundColor: "white",
                  borderRadius: "25px",
                  height: "40px",
                  padding: "0px 20px 0px 20px",
                  width: isMobile ? "100%" : "236px",
                }}
                onClick={decouvrir}
              >
                <CustomText
                  fontSize={"12px"}
                  fontWeight={700}
                  textTransform={"uppercase"}
                  fontFamily="Ricard-Black"
                >
                  participer au projet
                </CustomText>
              </Button> */}
            </Box>
          </Box>
        )}
      </Box>
      {menuOpen && (
        <Slide
          style={{ zIndex: 999 }}
          direction="right"
          in={menuOpen}
          mountOnEnter
          unmountOnExit
        >
          <Box
            sx={{
              width: "100%",
              bgcolor: baseColors.yellow,
              position: "fixed",
              top: 80,
              bottom: 0,
              left: 0,
              right: 0,
              height: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                margin: "10px",
              }}
            ></Box>
            <Box
              sx={{
                height: "80%",
                display: "flex",
                padding: "5px",
                alignItems: "center",
              }}
            >
              <Box>
                {itemsHamburger.map((item, index) => (
                  <LinkScroll
                    to={"section" + (index + 1).toString()}
                    smooth={"easeInOutQuart"}
                    duration={500}
                    
                    onClick={() =>
                      handleClick("section" + (index + 1).toString())
                    }
                  >
                    <Grid
                      container
                      key={index}
                      alignItems="center"
                      style={{
                        marginBottom: "20px",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      display={"flex"}
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      <Grid item xs={2}>
                        <Box>
                          <CustomText fontSize={"16px"} fontWeight={900} fontFamily="Ricard-Black" >
                            {index + 1}
                          </CustomText>
                        </Box>
                      </Grid>
                      <Grid item xs={10}>
                        <CustomText
                          fontSize={"16px"}
                          fontWeight={900}
                          textTransform="uppercase"
                          fontFamily="Ricard-Black"
                        >
                          {item}
                        </CustomText>
                      </Grid>
                    </Grid>
                  </LinkScroll>
                ))}
              </Box>
            </Box>
          </Box>
        </Slide>
      )}
      <Box flexDirection="row" className="wrapper">
        <Box className={classes.content}>{children}</Box>
      </Box>

      {button}
      <Box className={classes.footer}>
        <CustomText fontSize={isMobile? "10px" : "12px"} fontWeight={300}>
          {" "}
          L’ABUS D’ALCOOL EST DANGEREUX POUR LA SANTÉ, À CONSOMMER AVEC
          MODÉRATION.{" "}
        </CustomText>
      </Box>

      <Drawer
        open={openDrawer}
        anchor="top"
        style={{
          transition: "transform 1s ease",
          transform: openDrawer ? "translateY(0)" : "translateY(-100%)",
          height: "100vh",
        }}
        onClose={() => setOpenDrawer(false)}
      >
        <Box className={classes.header}>
          <AppBarHeader />
        </Box>
        <Box className={classes.boxDrawer}>
          <Box className={classes.contentDrawer}>
            <CustomText fontSize={"12px"} fontWeight={500}>
              Vous devez être majeur pour accéder au site
            </CustomText>
            <Box marginTop={"10px"}>
              <CustomText
                fontSize={"21px"}
                fontWeight={700}
                textTransform={"uppercase"}
                fontFamily="RicardAllonge-Black"
              >
                VEUILLEZ ENTRER VOTRE DATE DE NAISSANCE
              </CustomText>
            </Box>
            {errorAge ? (
              <CustomText color="red" fontSize={"13px"} fontWeight={400}>
                Vous n'avez pas l'âge requis pour accéder au site
              </CustomText>
            ) : (
              ""
            )}

            <Box
              display="flex"
              marginTop={"9px"}
              alignItems="center"
              justifyContent={"center"}
            >
              <CustomTextFields
                onChange={(value: any) => {
                  setDateOfBirth(value);
                }}
                label={""}
                placeholder={"AAAA"}
                width={"300px"}
              />
            </Box>

            <Box display="flex" alignItems="center" justifyContent={"center"}>
              <CustomCheckbox
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span>
                <CustomText fontSize={"13px"} fontWeight={300}>
                  Se souvenir de moi
                </CustomText>
              </span>
            </Box>

            <CustomText fontSize={"10px"} fontWeight={400}>
              Ne cochez pas cette case si votre ordinateur est accessible à une
              personne mineure
            </CustomText>

            <Button
              style={{
                backgroundColor: baseColors.blue.dark,
                borderRadius: 0,
                height: "40px",
                width: "300px",
                marginTop: "10px",
              }}
              onClick={() => handleAgeValidation()}
            >
              <CustomText
                color="white"
                fontSize={"20px"}
                fontWeight={700}
                textTransform={"uppercase"}
                fontFamily="Ricard-Black"
              >
                entrer
              </CustomText>
            </Button>

            <Box marginTop={"10px"}>
              <a
                href={process.env.PUBLIC_URL + "/phpage/CGU.pdf"}
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <CustomText fontSize={"13px"} fontWeight={0}>
                  Mentions légales & CGU
                </CustomText>
              </a>
            </Box>

            <Box
              marginTop={"7px"}
              flex={1}
              overflow={"auto"}
              sx={{ scrollbarWidth: "none" }}
            >
              <CustomText fontSize={"10px"} fontWeight={400}>
                Le site que vous visitez utilise uniquement des cookies
                essentiels, exemptés du recueil de consentement préalable
              </CustomText>
            </Box>
          </Box>
        </Box>
        <Box className={classes.footer}>
          <CustomText fontSize={"10px"} fontWeight={300}>
            L’ABUS D’ALCOOL EST DANGEREUX POUR LA SANTÉ, À CONSOMMER AVEC
            MODÉRATION.
          </CustomText>
        </Box>
      </Drawer>
    </div>
  );
};

const Wrapper = (WrappedComponent: any) => () => {
  return (
    <WrapperComponent>
      <WrappedComponent />
    </WrapperComponent>
  );
};

export default Wrapper;
