import React from 'react'

const iconPlus: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M20 6.55343V14.349H13.8397V20.9024H6.16034V14.349H0V6.55343H6.16034V0H13.8397V6.55343H20Z"
        fill="#00226C"
      />
    </svg>
  )
}

export default iconPlus;