import React, { useState, FC, useContext, ReactNode } from 'react';
import { createContext } from 'react';
import { LocalStorageService } from '../Utils/storage';


type AuthType = {
    token: string;
    isLogged: boolean;
    login: (token: string) => void;
    logout: () => void;
};
interface ProviderProps {
    children: ReactNode;
}

export const AuthContext = createContext<AuthType>({
    token: '',
    login: () => { },
    logout: () => { },
    isLogged: false
});

const AuthProvider: FC<ProviderProps> = (props) => {
    const { children } = props;
    const [token, setToken] = useState<string>('');
   
    const isLogged = !!token;
    return (
        <AuthContext.Provider
            value={{
                token,
                isLogged,
                login: (loginToken: string) => {
                    setToken(loginToken);
                    localStorage.setItem("token", loginToken)
                    sessionStorage.setItem('isLogged', 'true');
                },
                logout: () => {
                    setToken('');
                    localStorage.setItem('isLogged', 'false');
                    localStorage.removeItem('sessionTokenData');
                    LocalStorageService.ClearStorage();
                },
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;