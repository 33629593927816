import React from "react";

const iconArrowLeftMob = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59"
      height="45"
      viewBox="-1 2 59 45"
      fill="none"
    >
      <g filter="url(#filter0_d_63_6009)">
        <path
          d="M26.0919 32.3429L17.5205 21.4743L26.0919 10.6743L30.8576 14.6857L25.4405 21.4743L30.8576 28.2972L26.0919 32.3429Z"
          fill="white"
        />
        <path
          d="M18.069 26.2745L18.5833 25.6231L15.3262 21.4745L25.8519 8.24023L33.2233 14.4459L32.0576 15.9545L35.9662 19.2459L30.9262 25.6231L33.2233 28.5374L32.6062 29.0859L35.9662 33.3374L28.5947 39.6117L18.069 26.2745ZM30.8576 28.2974L25.4405 21.4745L30.8576 14.6859L26.0919 10.6745L17.5205 21.4745L26.0919 32.3431L30.8576 28.2974Z"
          fill="#00226C"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_63_6009"
          x="-4"
          y="0"
          width="63"
          height="53"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_63_6009"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_63_6009"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default iconArrowLeftMob;
