import { Box, useMediaQuery } from "@mui/material";
import React, { FC, useState } from "react";
import TextureStyles from "./TextureStyles";
import { CustomText } from "../../../Components/CustomeText/CustomText";

interface TextureProps {
  selected: number;
  setSelectSide?: (value: string) => void;
  setImage: any;
}

export const Texture: FC<TextureProps> = (props) => {
  const { selected, setImage, setSelectSide } = props;
  const classes = TextureStyles();
  const [selectedTube, setSelectedTube] = useState(0);
  const isMobile = useMediaQuery("(max-width:800px)");
  const contenuOne = isMobile ? classes.boxOneMobile : classes.boxOne;
  const contenuImage = isMobile ? classes.imageMobile : classes.image;
  const [selectedSide, setSelectedSide] = useState('f');
  

  const handleImageSelection = (index: number) => {
    setSelectedTube(index);
    setImage(index);
  };

  return (
    <Box className={contenuOne}>
      <Box
        className={classes.boxTube}
        style={{ minHeight: 0, width: "auto", marginTop: "5px" }}
      >
        { [0, 1, 2, 3, 4, 5].map((index) => (
            <img
              key={`${index}`}
              src={require(`../../../assets/image/tub/${selected}/${index}.png`)}
              alt={`Image ${index}`}
              className={contenuImage}
              style={{
                background:
                  index === selectedTube 
                    ? "#51E0FF"
                    : "transparent",
              }}
              onClick={() => handleImageSelection(index)}
            />
          ))
          }
      </Box>
      <Box
        className={classes.boxTitle}
        style={{ minHeight: 0, overflow: "hidden" }}
      >
        <div
          style={{
            marginTop: "5px",
            height: "15px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomText
            color="white"
            fontSize={isMobile ? "20px" : "13px"}
            fontWeight={900}
            fontFamily="Ricard-Black"
            style={{ position: "absolute", zIndex: 1 }}
          >
            Couleur du tube
          </CustomText>
          <CustomText
            fontSize={isMobile ? "20px" : "13px"}
            fontWeight={900}
            fontFamily="RicardOmbre-Black"
            style={{ position: "absolute", zIndex: 2 }}
          >
            Couleur du tube
          </CustomText>
        </div>
      </Box>
    </Box>
  );
};
