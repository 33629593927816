const baseColors = {
    yellow:  "#FFC700",
    blue:{
        dark: "#00226C"
    },
    grey: "rgba(0, 35, 108, 0.70)",
    bluesky: '#45F0FB',
}
const baseFontFamily = {
    ricard: "Ricard-Normal, Helvetica",
    ricardAllonge: "RicardAllonge-Black, Helvetica"
}

export {
    baseColors,
    baseFontFamily
}