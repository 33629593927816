import React, { createContext, useContext, useState, ReactNode } from "react";

type TextColorType = {
  textColor: string;
  setTextColor: (val: string) => void;
  activeMenu: string;
  setActiveMenu: (val: string) => void;
}
interface TextProviderProps {
  children: ReactNode;
}

export const TextContext = createContext<TextColorType>({
  textColor: "",
  setTextColor: () => { },
  activeMenu: "",
  setActiveMenu: () => {}
});

const TextColorProvider = (props: TextProviderProps) => {
  const { children } = props;
  const [textColor, setTextColor] = useState("#00226C");
  const [activeMenu, setActiveMenu] = useState("");

  return (
    <TextContext.Provider value={{ textColor, setTextColor, activeMenu, setActiveMenu }}>
      {children}
    </TextContext.Provider>
  );
};

export default TextColorProvider;