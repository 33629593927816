import React from "react";

const iconYoutube = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="22"
      viewBox="0 0 30 22"
      fill="none"
    >
      <path
        d="M29.7012 4.55273C29.7012 4.55273 29.4082 2.48438 28.5059 1.57617C27.3633 0.38086 26.0859 0.375 25.5 0.304687C21.3047 -1.67638e-07 15.0059 0 15.0059 0H14.9941C14.9941 0 8.69531 -1.67638e-07 4.5 0.304687C3.91406 0.375 2.63672 0.38086 1.49414 1.57617C0.591797 2.48438 0.304688 4.55273 0.304688 4.55273C0.304688 4.55273 0 6.98438 0 9.41016V11.6836C0 14.1094 0.298828 16.541 0.298828 16.541C0.298828 16.541 0.591797 18.6094 1.48828 19.5176C2.63086 20.7129 4.13086 20.6719 4.79883 20.8008C7.20117 21.0293 15 21.0996 15 21.0996C15 21.0996 21.3047 21.0879 25.5 20.7891C26.0859 20.7188 27.3633 20.7129 28.5059 19.5176C29.4082 18.6094 29.7012 16.541 29.7012 16.541C29.7012 16.541 30 14.1152 30 11.6836V9.41016C30 6.98438 29.7012 4.55273 29.7012 4.55273ZM11.9004 14.4434V6.01172L20.0039 10.2422L11.9004 14.4434Z"
        fill="#00226C"
      />
    </svg>
  );
};

export default iconYoutube;
